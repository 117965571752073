import React, { useState } from "react";
import * as s from "./styles";
import ReactModal from 'react-modal'; //https://reactcommunity.org/react-modal/

/**Modal personalizado */
export default function Modal({ children, exibir, titulo = null, setStateExibir, styleContainerGeral = null, ...restProsp }) {

    //Fechar modal
    function fechar(valor) {
        setStateExibir(valor);
    }

    return (
        <ReactModal
            isOpen={exibir}
            appElement={document.getElementById('root')}
            style={{

            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999
            },

            content: {
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }

}}
        >
            <s.ContainerGeral style={styleContainerGeral} >
                <s.BtnFechar onClick={() => { fechar(false) }}>X</s.BtnFechar>
                {
                    titulo &&
                    <s.ContainerTitulo>
                        <h1>{titulo}</h1>
                    </s.ContainerTitulo>
                }
                <s.ContainerChildren {...restProsp} >
                    {children}
                </s.ContainerChildren>
            </s.ContainerGeral>
        </ReactModal>
    )
}