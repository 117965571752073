import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloContratos'
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import * as Fa from "react-icons/fa";
import { LuRadioTower } from "react-icons/lu";
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import CompAdicionarVenda from './CompNovaVenda/comp_nova_venda';
import Modal from '../../components/Modal/ModalPers';
import { dataExtenso } from '../../functions/fucoes';
import DetalhesCard from './DetalhesContrato/detalhes';
import { functionCor } from '../../utils/functions';
import CardListaPadrao from '../../components/card_lista_padrao/card_list';


export default function Contratos() {
  const { Spinner, setClienteSelecionado } = useContext(AuthContext);
  const [vendaSelecionada, setVendaSelecionada] = useState(null);
  const [listaContratos, setListaContratos] = useState([]);
  const [adicionarVenda, setAdicionarVenda] = useState(false);
  const [tipo, setTipo] = useState(2);
  const [Mdpagamentos, setMdpagamentos] = useState(false);
  const [MdDispositivos, setMdDispositivos] = useState(false);
  const [MdVeiculos, setMdVeiculos] = useState(false);
  const [MdDetalhes, setMdDetalhes] = useState(null);
  const [listPagamentos, setListPagamentos] = useState([]);
  const [listVeiculos, setListVeiculos] = useState([]);
  const [listDispositivos, setListDispositivos] = useState([]);

  const cor = ['#17A398', '#064789', '#C32C56']

  useEffect(() => {
    if (vendaSelecionada) {
      for (const key in vendaSelecionada) {
        const valor = vendaSelecionada[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }

    return () => { setClienteSelecionado(null) }
  }, [vendaSelecionada])

  useEffect(() => {
    if (!adicionarVenda) {
      setVendaSelecionada(null)
      buscarContratos()
    }

  }, [adicionarVenda])

  async function buscarContratos() {
    Spinner(true)
    setListaContratos([])

    try {
      const res = await api.get(`/contratos`)
      if (res.data.status) {
        setListaContratos(res.data.resultado)
        Spinner(false)
      } else {
        setListaContratos([])
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
        Spinner(false)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }

  async function pagamentos(id) {
    setListPagamentos([]);
    Spinner(true);
    try {
      const pay = await api.get(`pagamentos/idcontrato/${id}`);
      if (pay.data.status) {
        let dadosPagamento = pay.data.resultado;
        setListPagamentos(dadosPagamento);
      } else {
        AlertaDeMensagem('alerta', 'Nenhum resultado retornado', 'ALERTA', 3000);
      }
    } catch (error) {
      AlertaDeMensagem('erro', 'Erro ao buscar pagamentos', 'ERRO', 3000);
    } finally {
      Spinner(false);
    }
  }

  async function Veiculos(id) {
    setListVeiculos([]);
    Spinner(true);
    try {
      const veiculoos = await api.get(`/clientes/veiculos/${id}`);
      console.log('veiculoos :>> ', veiculoos);
      if (veiculoos.data.status) {
        let dadosVeiculos = veiculoos.data.resultado;
        setListVeiculos(dadosVeiculos);
      } else {
        AlertaDeMensagem('alerta', 'Nenhum resultado retornado', 'ALERTA', 3000);
      }
    } catch (error) {
      AlertaDeMensagem('erro', 'Erro ao buscar Veiculos', 'ERRO', 3000);
    } finally {
      Spinner(false);
    }
  }

  async function dispositivos(id) {
    setListDispositivos([]);
    Spinner(true);
    try {
      const Produto = await api.get(`/produtos/idcontrato/${id}`);
      console.log('Produto :>> ', Produto);
      if (Produto.data.status) {
        let dadosproduto = Produto.data.resultado;
        setListDispositivos(dadosproduto);
      } else {
        AlertaDeMensagem('alerta', 'Nenhum resultado retornado', 'ALERTA', 3000);
      }
    } catch (error) {
      AlertaDeMensagem('erro', 'Erro ao buscar dispositivos', 'ERRO', 3000);
    } finally {
      Spinner(false);
    }
  }


  async function gravar(tipo) {
    const arrayGeral = [];
    let contratoList = document.querySelectorAll(`.contrato [name]`)
    let contrato = null

    for (const el of contratoList) {
      if (el.id != 'nome_selected') {
        contrato = {
          ...contrato,
          [el.id]: el.value
        }
      }
    }

    let ele_ids = document.querySelectorAll(`.id_Veiculo`)
    for (const els of ele_ids) {
      const arrayIdProdts = [];
      let custTotal = 0;
      let produtsVinc = document.querySelectorAll(`.ids_produtos${els.id}`)
      for (const idsProds of produtsVinc) {
        let objPrd = JSON.parse(idsProds.value)
        arrayIdProdts.push
          ({
            id_veiculo: els.id,
            id_produto: objPrd.id_produto
          })
        custTotal += parseFloat(objPrd.custo)
      }
      arrayGeral.push(
        {
          ...contrato,
          array_veiculos: arrayIdProdts,
          [tipo == 'anual' ? 'valor_mensal' : 'valor_anual']: 0
        }
      )
    }

    if (arrayGeral.length > 0) {
      Spinner(true)

      for (const key in arrayGeral) {

        const objDds = arrayGeral[key];
        try {

          const res = await api.post(`/contratos/inserir`, objDds)

          if (res.data.status) {
            setAdicionarVenda(false)
            buscarContratos()
          } else {
            AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
          }

        } catch (error) {
          console.log('error :>> ', error);
          AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
          Spinner(false)
        }

      }
      Spinner(false)

    } else {
      Spinner(false)
      AlertaDeMensagem('alerta', 'Ação não pode ser gravada, tente novamente', 'ATENÇÃO!', 4000)
    }

  }

  let array_botoes = [
    {
      icone: <Fa.FaInfoCircle />,
      title: 'Detalhes',
      funcao: (e) => setMdDetalhes(e)
    },
    {
      icone: <LuRadioTower />,
      title: 'Dispositivos',
      funcao: (e) => {
        setMdDispositivos(true);
        dispositivos(e.id_contrato);
      }
    },
    {
      icone: <Fa.FaCreditCard />,
      title: 'Pagamentos',
      funcao: (e) => {
        setMdpagamentos(true);
        pagamentos(e.id_contrato);
      }
    },
    {
      icone: <Fa.FaCar />,
      title: 'Veículos',
      funcao: (e) => {
        setMdVeiculos(true);
        Veiculos(e.id_contrato);
      }
    },
  ]




  return (
    <s.ContainerGeral >
      {

        !adicionarVenda ?
          <s.SubContainerGeral>
            <s.ContaineRow cor={cor[tipo - 1]}>

              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setTipo(2) }}>
                <Fa.FaHandshake
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>CONTRATOS</p>
              </s.BotaoProdutos>

              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => { setAdicionarVenda(true) }}>
                <Fa.FaPlusSquare
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>NOVO</p>
              </s.BotaoProdutos>
            </s.ContaineRow>

            <s.ContainerScroll
              className='elemento'>

              {
                listaContratos.length > 0 ? (
                  <s.ContainerScroll>
                    {
                      listaContratos.map((c, index) => (

                        <>

                          <CardListaPadrao
                            index={index}
                            item={c}
                            titulo={`Contrato ${c.tipo_locacao == 'mensal' ? `Mensal` : `Anual`} Código : ${c.id_contrato}`}
                            detalhe1={`Cliente : ${c.nome} ${c.sobrenome}`}
                            detalhe2={`Código do cliente: ${c.id_cliente}`}
                            ativarClick={false}
                            defaultButon={true}
                            array_botoes={array_botoes}
                          />

                        </>
                      ))
                    }


                    {/* Modal que exibe os detalhes  */}
                    <Modal
                      exibir={MdDetalhes != null}
                      setStateExibir={() => setMdDetalhes(null)}
                    >
                      <DetalhesCard e={MdDetalhes} />
                    </Modal>

                    {/* Modal que exibe a lista de despositivos  */}
                    <Modal
                      exibir={MdDispositivos}
                      setStateExibir={setMdDispositivos}
                    >
                      <s.ContainerModDin2>
                        <s.TituloVeic>Dispositivos</s.TituloVeic>
                        {
                          listDispositivos.length > 0 ? (
                            listDispositivos.map((dispo, index) => {

                              return (
                                <s.Cardveiculo key={index}>
                                  <s.ContVeiculosD>
                                    <s.ContImgVeiculo><img src={dispo.foto} /></s.ContImgVeiculo>
                                    <s.ContDadosVeiculo>
                                      <h1>Nome: {dispo.nome}</h1>
                                      <p>Nº de Série :  {dispo.numero_serie}</p>
                                      <p> Categoria : {dispo.categoria} </p>
                                      <p> Custo : {dispo.custo} </p>
                                      <p>{dispo.descricao} </p>
                                      <p>status : {dispo.status} </p>
                                    </s.ContDadosVeiculo>

                                  </s.ContVeiculosD>



                                </s.Cardveiculo>
                              )
                            }
                            )
                          )
                            :
                            (
                              <p>Nenhum Veiculo encontrado.</p>
                            )
                        }
                      </s.ContainerModDin2>
                    </Modal>
                    {/* Modal que exibe a lista de pagamentos  */}
                    <Modal
                      exibir={Mdpagamentos}
                      setStateExibir={setMdpagamentos}
                    >
                      <s.ContainerModDin2>
                        <h1>PAGAMENTOS</h1>
                        {
                          listPagamentos.length > 0 ? (
                            listPagamentos.map((p, index) => {
                              return (
                                <s.Card key={p?.id} status={p?.status}>
                                  <s.CardTitle>ID: {p?.id}</s.CardTitle>
                                  <s.CardContent>Data do p: {dataExtenso(new Date(p.data_pagamento).toLocaleDateString(), '/')}</s.CardContent>
                                  <s.CardContent>Valor: R$ {p?.valor_pago}</s.CardContent>
                                  <s.CardContent>Status: {p?.status}</s.CardContent>
                                </s.Card>
                              )
                            }
                            ))
                            :
                            (
                              <p>Nenhum pagamento encontrado.</p>
                            )
                        }
                      </s.ContainerModDin2>

                    </Modal>
                    {/* Modal que exibe a lista de veiculos  */}
                    <Modal
                      exibir={MdVeiculos}
                      setStateExibir={setMdVeiculos}
                    >
                      <s.ContainerModDin2>
                        <s.TituloVeic>Veiculos</s.TituloVeic>
                        {
                          listVeiculos.length > 0 ? (
                            listVeiculos.map((veiculo, index) => {
                              return (
                                <s.Cardveiculo key={index}>
                                  <s.ContVeiculosD>
                                    <s.ContImgVeiculo><img src={veiculo.foto} /></s.ContImgVeiculo>
                                    <s.ContDadosVeiculo>
                                      <h1> {veiculo.marca} - {veiculo.modelo}</h1>
                                      <p>Placa: {veiculo.placa}</p>
                                      <p> Categoria : {veiculo.categoria} </p>
                                      <p> Localidade : {veiculo.localidade} </p>
                                      <p>{veiculo.observacao} </p>
                                    </s.ContDadosVeiculo>

                                  </s.ContVeiculosD>

                                </s.Cardveiculo>
                              )
                            }
                            )
                          )
                            :
                            (
                              <p>Nenhum Veiculo encontrado.</p>
                            )
                        }
                      </s.ContainerModDin2>
                    </Modal>

                  </s.ContainerScroll>
                ) : (
                  <s.DivVaziaImg>
                    <h1>Nunhum contrato cadastrado</h1>
                  </s.DivVaziaImg>
                )
              }
            </s.ContainerScroll>
          </s.SubContainerGeral>
          :
          <CompAdicionarVenda
            id='produtos'
            cor={cor[tipo - 1]}
            setAdicionarVenda={setAdicionarVenda}
            gravar={gravar}
          />
      }
    </s.ContainerGeral>

  )
}
