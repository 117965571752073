import { useContext, useEffect, useState } from 'react';
import * as s from './stylefiltro';
import * as Icon from "react-icons/md";
import InputNTD from '../InputPersonalizado/input_personalizavel';
import AlertaDeMensagem from '../AlertaMensagem/AlertaDeMensagem';
import api from '../../services/apiAxios';
import { AiOutlineClose } from 'react-icons/ai';
import { AuthContext } from '../../contexts/authContext';
const check = 'checkbox'
const date = 'date'


export default function MenuFiltro({
    menufiltro,
    setMenufiltro,
    fechaModal,
    setFechaModal,
    array_inputs = [],
    onclickpesquisar = undefined,
    setStateFiltrada
}) {
    const { Spinner } = useContext(AuthContext)
    const [query, setQuery] = useState(array_inputs);

    async function chamaFiltro(objQuery, por, rota) {
        Spinner(true);
        let strQuery = '';
        let primeiraQuery = true;

        for (const key in objQuery) {
            const element = objQuery[key];

            if (element.type == 'text' && element.inpt) {
                strQuery = `${primeiraQuery ? '?' : '&'}${element.query}=${element.valorcp}`
                primeiraQuery = false;
            } else if (element.inpt) {
                strQuery += `${primeiraQuery ? '?' : '&'}${element.Contem_dt_inicio ? element.querydt_in : querydt_Fim}=${element.Contem_dt_inicio ? element.valueDt_in : element.valueDt_fim}`;
                primeiraQuery = false;
            }
        }
        const filtro = await api.get(`pagamentos/filtroFinanceiro${strQuery}`)
        if (filtro.data.status) {
            setStateFiltrada(filtro.data.resultado)
        } else {
            AlertaDeMensagem(`alerta`, 'Nenhum Resultado Retornado ,', 'ATENÇÃO', 3000)
            setStateFiltrada([])
        }
        Spinner(false);
    }

    function toogleInpt(obj, indice, chave) {
        let valor = obj[chave]
        const arrayquery = [...query]
        arrayquery[indice][chave] = !valor
        setQuery(arrayquery)
    }
    function ManipulaObjQuery(obj, indice, strquery, chave, valorDoipt) {
        const arrayquery = [...query]
        arrayquery[indice][chave] = valorDoipt
        setQuery(arrayquery)
    }

    function encerrarModal() {
        setFechaModal(true);
        setTimeout(() => {
            setFechaModal(false);
            setMenufiltro(false);
        }, 800); // tempo
    }


    return (

        <s.ContainerFiltro
            fecha={fechaModal}
        >
            <s.ContentFilt>
                <s.ContTopo>
                    <s.TituFiltro>Selecione o Filtro</s.TituFiltro>
                    <s.BotX onClick={() => encerrarModal()}> <AiOutlineClose size={15} /></s.BotX>
                </s.ContTopo>
                <s.FiltroContainerbass>
                    <s.ContentPs>
                        {
                            query?.map((ele, ind) => {
                                return (
                                    !ele.oculto &&
                                    <>
                                        <s.ContCheck1>
                                            <input
                                                onClick={(e) => {
                                                    toogleInpt(ele, ind, 'inpt')
                                                }
                                                }
                                                type={check}
                                            />
                                            <label>{ele.title}</label>
                                        </s.ContCheck1>

                                        {
                                            (ele.inpt &&
                                                ele.type == 'text') ?
                                                < s.ContSelect onChange={(e) =>
                                                    ManipulaObjQuery(ele, ind, ele.query, 'valorcp', e.target.value)
                                                }>
                                                    <input
                                                        type="text"
                                                    />
                                                </s.ContSelect>
                                                : (ele.inpt &&
                                                    ele.type == date) ?
                                                    <>
                                                        {ele.Contem_dt_inicio &&
                                                            <>
                                                                <s.LabelDt>{ele.labelDt_in}</s.LabelDt>
                                                                < s.ContSelectDat >
                                                                    <input
                                                                        onChange={(e) =>
                                                                            ManipulaObjQuery(ele, ind, ele.querydt_in, 'valueDt_in', e.target.value)
                                                                        }
                                                                        type={date}

                                                                    />
                                                                </s.ContSelectDat>
                                                            </>
                                                        }
                                                        {ele.Contem_dt_fim &&
                                                            <>
                                                                <s.LabelDt>{ele.labelDt_fim}</s.LabelDt>
                                                                < s.ContSelectDat2 >
                                                                    <input type={date}
                                                                        onChange={(e) =>
                                                                            ManipulaObjQuery(ele, ind, ele.querydt_Fim, 'valueDt_fim', e.target.value)
                                                                        }
                                                                    />
                                                                </s.ContSelectDat2>
                                                            </>
                                                        }
                                                    </>
                                                    : (ele.inpt &&
                                                        ele.type == 'select') &&
                                                    < s.ContSelect >
                                                        <select
                                                            onChange={(e) =>
                                                                ManipulaObjQuery(ele, ind, ele.query, 'valorcp', e.target.value)
                                                            }
                                                        >
                                                            {ele?.options?.map((elementos, i) => {
                                                                return (
                                                                    <option
                                                                        key={elementos.value}
                                                                        value={elementos.value}
                                                                    >{elementos.label}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </s.ContSelect>

                                        }


                                    </>
                                )
                            }
                            )
                        }
                        <s.BotaoPesquisa onClick={() => {
                            chamaFiltro(query, 'filtro')
                        }
                        } >
                            <div>PESQUISAR</div>
                        </s.BotaoPesquisa>
                    </s.ContentPs>

                </s.FiltroContainerbass>
            </s.ContentFilt>
        </s.ContainerFiltro>
    );
}