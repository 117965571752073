import React from 'react';
import * as s from './stylepesquisa';
import { BsSearch } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import { MdOutlineSearchOff } from "react-icons/md";
import { TbFilterOff } from "react-icons/tb";

export default function InputPesquisa({
    placeholder = 'Pesquisar',
    onInputChange,
    onFilterClick,
    onSearchClick,
    showFilter = true,
    isFiltered = true,
    isSearching = true,
    children,
    ...resProps
}) {
    return (
        <s.ContainerGeral>
            <s.Pesquisa
                placeholder={placeholder}
                {...resProps}
                onChange={onInputChange}
            >
                {children}
            </s.Pesquisa>
            {showFilter && (
                <s.ContainerFilter onClick={onFilterClick}>
                    {isFiltered ? <FiFilter /> : <TbFilterOff size={18} />}
                </s.ContainerFilter>
            )}
            <s.Containerlupa onClick={onSearchClick}>
                {isSearching ? <BsSearch /> : <MdOutlineSearchOff size={20} />}
            </s.Containerlupa>
        </s.ContainerGeral>
    );
}