import React, { useContext, useEffect, useState } from 'react';
import * as s from './estiloFinanceiro'
import * as Fa from "react-icons/fa";
import { array_cores_telas } from '../../styles/coresPadroes';
import api from './../../services/apiAxios';
import { FaUserGroup } from "react-icons/fa6";
import { AuthContext } from '../../contexts/authContext';
import InputPesquisa from '../../components/InputPesquisa/InputPesquisa';
import MenuFiltro from '../../components/filtro/menu_filtro';
import noResult from './img/noResult.png';
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BiSolidMessageSquareError, BiSolidPhoneOutgoing } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import CardListaPadrao from '../../components/card_lista_padrao/card_list';
import { BsExclamationSquareFill, BsWhatsapp } from 'react-icons/bs';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';

const hover = 'hover'

// Componente principal
const FinanceiroScreen = () => {
  const { Spinner } = useContext(AuthContext)
  const [fechaModal, setFechaModal] = useState(false); // animação pra Fechar o modal 
  const [inadimplencias, setInadimplencias] = useState([])
  const [inadimplencias2, setInadimplencias2] = useState([])
  const [tipo, setTipo] = useState(1)
  const [nome, setNome] = useState('')
  const [menuFiltro, setMenuFiltro] = useState(false)

  // modais e seus dados 
  const [Detalhes, setDetalhes] = useState([])
  const [mdDetalhes, setMdDetalhes] = useState(false)
  const [confirmar, setConfirmar] = useState([])
  const [mdConfirmar, setMdConfirmar] = useState(false)

  const [dadoScobrar, setDadoScobrar] = useState([])
  const [mdCobrar, setMdCobrar] = useState(false)


  const inputs_filtro2 = [
    {
      title: 'Código Cliente',
      type: 'text',
      query: 'id_cliente',
      oculto: false,
      inpt: false,
      valorcp: null,
    },
    // {
    //   title: 'Valor Pendência',
    //   type: 'text',
    //   query: 'id_cliente',
    //   oculto: false,
    //   inpt: false,
    //   valorcp: null,
    // },
    // {
    //   title: 'Data',
    //   type: 'date',
    //   querydt_in: 'data_inicio',
    //   labelDt_in: 'Data inicio',
    //   labelDt_fim: 'Data Final',
    //   querydt_Fim: 'data_fim',
    //   oculto: false,
    //   Contem_dt_inicio: true,
    //   Contem_dt_fim: true,
    //   inpt: false,
    //   valueDt_in: null,
    //   valueDt_fim: null,
    // },
  ]


  useEffect(() => {
    buscarInadiplencias()

  }, [tipo])

  async function buscarInadiplencias() {
    Spinner(true)

    try {
      const res = await api.get(`pagamentos/pendentes`)
      setInadimplencias(res.data.resultado);
      setInadimplencias2(res.data.resultado);
      Spinner(false)

    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);
    }
  }
  async function getDetalhes(obj) {
    Spinner(true);
    try {
      const dtPay = await api.get(`/pagamentos/idcliente/${obj.id_cliente}?status=pendente&atrasado=1`)
      if (dtPay.data.status) {
        const novosDds = [];
        let ar = dtPay.data.resultado
        for (const key in ar) {
          let obj = null;
          const element = ar[key];
          obj = {
            ...element,
            selecionado: 0,
            borda: false
          }
          novosDds.push(obj)
        }
        setDetalhes(dtPay.data.resultado)
        setConfirmar(novosDds)
      } else {
        console.log('NENHUMA DIVIDA');
      }

    } catch (error) {

    }
    Spinner(false);
  }


  let array_botoes = [
    {
      icone: <BiSolidMessageSquareError />,
      title: 'Detalhes',
      funcao: (e) => { setMdDetalhes(true); getDetalhes(e) }
    },
    {
      icone: <Fa.FaCheckSquare />,
      title: 'Confirmar',
      funcao: (e) => { setMdConfirmar(true); getDetalhes(e) }
    },
    {
      icone: <Fa.FaPhoneSquareAlt />,
      title: 'Cobrar',
      funcao: (e) => { setMdCobrar(true); setDadoScobrar(e) }
    },
  ]

  async function filtroDTela(texto, lupa) {
    let lista = [...inadimplencias]
    if (lupa) {
      Spinner(true);
      try {
        const filtro = await api.get(`pagamentos/filtroFinanceiro?nome=${texto}`)
        if (filtro.data.status) {
          setInadimplencias2(filtro.data.resultado)
        } else {
          setInadimplencias2(inadimplencias)
        }
      } catch (error) { }
      Spinner(false);
    } else {
      let novoArray = lista.filter(

        e =>
          e?.nome?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.sobrenome?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.id_cliente?.toString()?.toLowerCase().includes(texto?.toLowerCase())
      )
      setInadimplencias2(novoArray)
    }
  }

  function trataCel(numero) {

    let apenasNumeros = numero.replace(/\D/g, '');

    // Adiciona o código do país (55) se não estiver presente
    if (!apenasNumeros.startsWith('55')) {
      apenasNumeros = '55' + apenasNumeros;
    }

    // Certifica-se de que tem exatamente 13 dígitos (55 + 11)
    if (apenasNumeros.length === 13) {
      return apenasNumeros; // Retorna o número limpo no formato esperado
    } else {
      return 'Número inválido'; // Retorna erro se o número não estiver correto
    }
  }

  function chamaZap(obj) {
    let mensagem = 'Olá, essa Mensagem é pra teste de cobrança e ainda está sendo Configurada Favor ignorar!'
    const numeroFone = trataCel(obj.fone)
    window.open(`https://api.whatsapp.com/send?phone=${numeroFone}&text=${encodeURI(mensagem)}`, '_blank')

  }

  function alteraObjPg(dt, indic, valor) {

    const arr = [...confirmar]
    arr[indic] = {
      ...dt,
      selecionado: valor ? 1 : 0,
      borda: valor
    }
    setConfirmar(arr)
  }
  async function confirmaSel() {
    Spinner(true);
    const inptsConfirmados = [...confirmar]
    try {
      for (const key in inptsConfirmados) {
        const element = inptsConfirmados[key];
        if (element.selecionado == 1) {
          const alteraStatus = await api.get(`pagamentos/confirmarpagamento?id_pagamento=${element.id}`)
          if (alteraStatus.data.status) {
            AlertaDeMensagem(`sucesso`, 'Pagamento de Debito Confirmado', 'SUCESSO', 3000)
            setMdConfirmar(false)
            setConfirmar([]);
          } else {
            AlertaDeMensagem(`falha`, `${alteraStatus.data.mensagem}`, 'FALHA', 3000)
          }
        }
      }

    } catch (error) {
      console.log('error :>> ', error);
    }
    Spinner(false);
  }


  const copiaEmail = (email) => {
    navigator.clipboard.writeText(email).then(() => {
      AlertaDeMensagem(`sucesso`, 'Email Copiado pra area de Transferencia', 'SUCESSO', 3000)
    }).catch(err => {
      console.error("Falha ao copiar", err);
    });
  };

  return (
    <s.Container>

      <s.Header>

        <s.ContaineRow cor={array_cores_telas[tipo - 1]}>

          <s.BotaoProdutos
            cor={tipo == 1 ? array_cores_telas[tipo - 1] : '#A5ABBA'}
            className='container__addIcon'
            onClick={() => { setTipo(1) }}>
            <FaUserGroup
              className='fa fa-times Icon'
              id='Icon'
              aria-hidden='true'
            />
            <p>Clientes Inadimplentes</p>
          </s.BotaoProdutos>

          <s.BotaoProdutos
            cor={tipo == 2 ? array_cores_telas[tipo - 1] : '#A5ABBA'}
            className='container__addIcon'
            onClick={() => { setTipo(2) }}>

            <Fa.FaMoneyCheck
              className='fa fa-times Icon'
              id='Icon'
              aria-hidden='true'
            />

            <p>Boletos a vencer</p>
          </s.BotaoProdutos>

        </s.ContaineRow>

      </s.Header>


      {menuFiltro &&
        <MenuFiltro
          menufiltro={menuFiltro}
          fechaModal={fechaModal}
          setFechaModal={setFechaModal}
          setMenufiltro={setMenuFiltro}
          array_inputs={inputs_filtro2}
          onclickpesquisar={undefined}
          setStateFiltrada={setInadimplencias2}
        />
      }

      {mdDetalhes &&
        <s.ModalDetalhes>
          <s.ConteudoModal >
            <s.TopoModal>
              <div></div>
              <s.Titulo>Detalhes do Pagamento</s.Titulo>
              <s.IconeFechar onClick={() => setMdDetalhes(false)}>
                <AiOutlineClose size={15} />
              </s.IconeFechar>
            </s.TopoModal>

            {
              Detalhes.map((dt, idic) =>
                <s.ContContrato key={idic}>
                  <s.Linha>
                    <s.Label>ID do Contrato: {dt.id_contrato}</s.Label>
                    <s.Valor>{dt.id_contrato}</s.Valor>
                  </s.Linha>
                  <s.Linha>
                    <s.Label>Data do Pagamento:</s.Label>
                    <s.Valor>

                      {new Date(dt.data_pagamento).toLocaleDateString()}
                    </s.Valor>
                  </s.Linha>
                  <s.Linha>
                    <s.Label>Valor a pagar:</s.Label>
                    <s.Valor>
                      {dt.valor_pago}
                    </s.Valor>
                  </s.Linha>
                  <s.Linha>

                    <s.Label>Status:</s.Label>
                    <s.Status status={dt.status}>
                      {dt.status}
                    </s.Status>
                  </s.Linha>
                </s.ContContrato>
              )
            }

          </s.ConteudoModal>
        </s.ModalDetalhes>
      }
      {mdConfirmar &&
        <s.ModalDetalhes>
          <s.ConteudoModal >
            <s.TopoModal>
              <div></div>
              <s.Titulo>Confirmar Pagamento</s.Titulo>
              <s.IconeFechar onClick={() => setMdConfirmar(false)}>
                <AiOutlineClose size={15} />
              </s.IconeFechar>
            </s.TopoModal>

            {
              confirmar.map((dt, idic) => {
                return (
                  <s.ContContrato2 borda={dt.borda.toString()} key={idic}>
                    <s.CtInptSel>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          alteraObjPg(dt, idic, e.target.checked)
                        }
                      />
                    </s.CtInptSel>
                    <s.ContInfoCtrato>
                      <s.Label3>Código: {dt.id}</s.Label3>
                      <s.Label3>Código do Contrato: {dt.id_contrato}</s.Label3>
                      <s.Label3>Data do Pagamento:  {new Date(dt.data_pagamento).toLocaleDateString()}</s.Label3>
                      <s.Label3>Valor a pagar:  {dt.valor_pago}</s.Label3>
                      <s.sts>Status: <b>{dt.status}</b> </s.sts>
                    </s.ContInfoCtrato>

                  </s.ContContrato2>
                )
              }
              )
            }
            <s.FooterModal>
              <s.botaoConfirm
                onClick={() => confirmaSel()}
              >Confirmar Selecionados</s.botaoConfirm>
            </s.FooterModal>
          </s.ConteudoModal>
        </s.ModalDetalhes>
      }

      {
        mdCobrar &&
        <s.ModalDetalhes>
          <s.ConteudoModal >
            <s.TopoModal>
              <div></div>
              <s.Titulo>CONTATO</s.Titulo>
              <s.IconeFechar onClick={() => setMdCobrar(false)}>
                <AiOutlineClose size={15} />
              </s.IconeFechar>
            </s.TopoModal>


            <s.ContContato >
              <s.Linha2>
                <s.ContImg>
                  <img src={dadoScobrar?.foto_cliente} />
                </s.ContImg>
                <s.Contdads>
                  <b> {dadoScobrar?.nome} {dadoScobrar?.sobrenome} </b>
                </s.Contdads>
              </s.Linha2>
              <s.LinhaFone
                onClick={() =>
                  chamaZap(dadoScobrar)
                }
                className={hover}
              >
                <s.Label>Fone : {dadoScobrar.fone}</s.Label>
                <s.Valor2>
                  <BsWhatsapp
                    size={20}
                    color='#2ecc71'
                  />
                </s.Valor2>
              </s.LinhaFone>
              <s.LinhaFone
                className={hover}
                onClick={() => copiaEmail(dadoScobrar.email)}>
                <s.Label >E-mail : {dadoScobrar.email}</s.Label>
                <s.Valor3><FiCopy size={20} /></s.Valor3>
              </s.LinhaFone>
            </s.ContContato>

          </s.ConteudoModal>
        </s.ModalDetalhes>
      }




      {
        tipo == 1 &&
        <s.Content>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2px',
            }}
          >
            <InputPesquisa
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  filtroDTela(nome, true)
                }
              }}
              placeholder="Digite sua pesquisa"
              onInputChange={(e) => { filtroDTela(e.target.value, false), setNome(e.target.value) }}
              onSearchClick={() => filtroDTela(nome, true)}
              onFilterClick={() => setMenuFiltro(true)}
              isFiltered={true}
            />
          </div>
          {inadimplencias2.length > 0 ?
            inadimplencias2.map((item, index) => (

              <CardListaPadrao
                index={index}
                item={item}
                foto={item.foto_cliente}
                titulo={`${item.nome} ${item.sobrenome}`}
                detalhe1={`Código do cliente ${item.id_cliente}`}
                detalhe2={`Qtd. Pg. pendentes: ${item.total_pagamentos_pendentes}`}
                detalhe3={`Total: R$ ${item.total_valor_pendente}`}
                ativarClick={false}
                defaultButon={true}
                array_botoes={array_botoes}
              />

            ))
            :
            <s.containerNoResult>
              <p>Nenhum Cliente Inadimplente Encontrado</p>
              <s.ContimgN>
              <img src={noResult} />
              </s.ContimgN>
            </s.containerNoResult>
          }
        </s.Content>
      }

      {
        tipo == 2 &&
        <s.Content>
          Lista de boletos a vencer
        </s.Content>
      }

    </s.Container >
  );
};

export default FinanceiroScreen;
