import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloLista";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import Pesquisa from "../../../components/pesquisa/pesquisa";
import * as Fa from "react-icons/fa";
import AlertaDeMensagem from "../../../components/AlertaMensagem/AlertaDeMensagem";
import semcar from "../../../img/car-icon.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Veiculos from "../cadveiculo/veiculos";
import { functionCor } from './../../../utils/functions';
import CardListaPadrao from "../../../components/card_lista_padrao/card_list";


export default function ListaVeiculos({ id_cliente2 }) {
    const { id_cliente } = useParams()
    const navigate = useNavigate()
    const { Spinner } = useContext(AuthContext);
    const [listaVeiculo, setListaVeiculo] = useState([]);
    const [listaVeiculo2, setListaVeiculo2] = useState([]);
    const [addEditVeiculo, setAddEditVeiculo] = useState(null);

    let id_clienteFiltro = (id_cliente || id_cliente2)


    useEffect(() => {
        getVeiculos(id_clienteFiltro)
    }, []);

    async function getVeiculos(id_clienteFiltro) {
        Spinner(true);
        try {
            const consulta = await api.get(`/clientes/veiculoscliente/${id_clienteFiltro}`)
            if (consulta.data.status) {
                setListaVeiculo(consulta.data.resultado)
                setListaVeiculo2(consulta.data.resultado)
            } else {
                AlertaDeMensagem(
                    "alerta",
                    "Este cliente não possui veiculos ",
                    "ATENÇÃO!",
                    4000
                );
            }
        } catch (error) {
            console.log(error);
        }
        Spinner(false)
    }

    function filtraLista(texto) {
        let novoArray = listaVeiculo2.filter(
            (e) =>
                e.renavam.toLowerCase().includes(texto.toLowerCase()) ||
                e.marca.toLowerCase().includes(texto.toLowerCase()) ||
                e.categoria.toLowerCase().includes(texto.toLowerCase()) ||
                e.modelo.toLowerCase().includes(texto.toLowerCase()) ||
                e.ano_fabricacao.toString().includes(texto) ||
                e.ano_modelo.toString().includes(texto) ||
                e.chassi.toString().includes(texto) ||
                e.localidade.toString().includes(texto) ||
                e.id_veiculo.toString().includes(texto)
        );
        setListaVeiculo(novoArray);
    }
    function editarouAddVeiculo(obj, verbo) {
        const obj2 = {
            ...obj,
            verbo: verbo
        }
        setAddEditVeiculo(obj2)
        // navigate(`/cadveiculos/${encodeURIComponent(JSON.stringify(obj2))}`);
    }

    let array_botoes = [
        {
            icone: <Fa.FaEdit />,
            title: 'Editar',
            funcao: (e) => editarouAddVeiculo(e, 'update')
        },

    ]

    return (
        <>
            {
                addEditVeiculo != null ?
                    <Veiculos dados={addEditVeiculo}
                        setAddEditVeiculo={setAddEditVeiculo}
                    />
                    :

                    <s.ContainerGeral>
                        <s.ContaineRow2>
                            <div id="container__voltaIcon">
                                <Fa.FaArrowAltCircleLeft
                                    onClick={() => { navigate(`/clientes`) }}
                                    className="fa fa-times Icon"
                                    aria-hidden="true"
                                />
                            </div>


                            <Pesquisa
                                placeholder="Pesquisa de Veiculos"
                                setValue={filtraLista}
                            />
                            <div id="container__addIcon">
                                <Fa.FaRegPlusSquare
                                    onClick={() => {
                                        editarouAddVeiculo(
                                            { id_cliente: id_clienteFiltro, },
                                            'insert'
                                        )
                                    }}
                                    className="fa fa-times Icon"
                                    id="Icon"
                                    aria-hidden="true"
                                />
                            </div>
                        </s.ContaineRow2>


                        <s.ContCompCard >
                            {listaVeiculo.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <CardListaPadrao
                                            index={index}
                                            item={item}
                                            foto={item?.foto || semcar}
                                            titulo={`${item.marca} - ${item.modelo}`}
                                            detalhe1={`Código do Veiculo ${item?.id_veiculo}`}
                                            detalhe2={`Placa: ${item.placa}`}
                                            detalhe3={`Categoria : ${item.categoria}`}
                                            detalhe4={`Localidade : ${item.localidade}`}
                                            detalhe5={`${item.observacao}`}
                                            ativarClick={false}
                                            defaultButon={true}
                                            array_botoes={array_botoes}
                                        />
                                    </div>
                                )
                            }
                            )}
                        </s.ContCompCard>
                    </s.ContainerGeral>

            }


        </>
    );
}