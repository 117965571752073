import styled from 'styled-components';

// Estilos com Styled Components
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: 100vh;
  background-color: #f5f5f5;
`;
// Header com navegação
export const Header = styled.header`
  width: 100%;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;
export const ContaineRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
 

    @media only screen and (max-width: 768px){
        border-bottom: none;

        .container__addIcon  {

            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;
export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;
export const Logo = styled.h1`
  font-size: 1.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
export const Nav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
export const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Resto da tela de financeiro
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  gap: 15px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 0.9rem;
  }
`;

export const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
`;

export const ListItem = styled.li`
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ListItemText = styled.span`
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const ListItemAmount = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: red;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

//Card Cliente
export const ContainerCard = styled.div`
  padding: 20px 35px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};

  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
    background-color: #469cac;
  }
`;
export const Greeting = styled.div`
  h1 {
    font-size: 18px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
`;
////////////////////////////////////////////
export const ModalDetalhes = styled.div`
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  min-height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: column;
`;
export const TopoMd = styled.div`
display: flex;
`;
export const contentDtalhes = styled.div`
  min-height: 350px;
  min-width: 335px;
  max-width: 335px;
  background-color: red;
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

// Styled Components
export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin: 20px;
    width: 90%;
  }
`;

export const TopoModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
background-color: #fff;

  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
`;
export const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
background-color: white;

  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px 0px;
  :hover {
    background-color: orange;
  }
`;



export const botaoConfirm = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  min-height: 30px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  background-color: green;
  border-radius: 5px;
`;
export const Titulo = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
`;

export const IconeFechar = styled.div`
display: flex;
align-items: center;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
  &:hover {
    color: #e74c3c;
  }
`;

export const ConteudoModal = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  max-height: 500px;
  min-height: 500px;
  padding: 0px 15px ;
  background-color: white;
  overflow: auto;

  gap: 25px;
`;
export const ContContrato = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #cecece;
  justify-content: center;
  border-radius: 5px;
  div {
    width: 40%;
    @media (max-width: 500px) {
    width: 100%;
  }}
`;

export const Linha = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 5px;
  border-bottom: 1px solid #f0f0f0;
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #666;
`;

export const Valor = styled.span`
  font-size: 12px;
  color: #333;
`;
export const Valor2 = styled.span`
  font-size: 12px;
  color: #333;
  padding-right: 10px;
`;
export const Valor3 = styled.span`
  font-size: 12px;
  color: #333;
  padding-right: 10px;
  
`;



export const Status = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => (props.status === "pendente" ? "#e74c3c" : "#2ecc71")};
`;

export const ContContato = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #cecece;
  justify-content: center;
  border-radius: 5px;
  max-width: 335px;
  min-width: 335px;
  .hover:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const LinhaFone = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 8px 5px;
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
  cursor: pointer;
`;
export const Linha2 = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 8px 5px;
  border-bottom: 1px solid #f0f0f0;
 
`;
export const ContImg = styled.div`
 display: flex;
 justify-content: center;
 width: 100%;
 img {
   max-height: 70px;
   border-radius: 50%;

 }
 flex: 1;
`;
export const Contdads = styled.div`
  flex: 4;
`;

// modal Confirm

export const ContContrato2 = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  width: 100%;
  min-width: 330px;
  padding: 0px 10px;
  background-color: ${b => b.borda == 'true' ? '#eaf9ff' : '#fff'};
`;

export const CtInptSel = styled.div`
  input[type="checkbox"] {
    height: 18px !important;
   width: 18px;
  }
`;
export const ContInfoCtrato = styled.div`
display: flex;
 flex-direction: column;
 padding: 5px;
`;
export const Label3 = styled.div`
  font-size: 10px;
  font-weight: bold;

`;
export const sts = styled.span`
  font-size: 10px;
  font-weight: bold;
  b {
    color: #e74c3c;
    
  }
  `;
  export const ContimgN = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    img {
      max-height: 400px;
  
    }
  `;
  export const containerNoResult = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
      width: 100%;

      font-size: 14px;
      margin: 5px 0px;
    }
  `;