import './sidebar.css'
import logo from '../../img/logo-stock2.png'
import * as Fa from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import AlertaDeMensagem from '../AlertaMensagem/AlertaDeMensagem';

const Sidebar = ({ statusSidebar, setStatusSidebar, deslogar, user }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    let administrador = user.nivel_usuario_sistema == 1
 

    let linksAdm = [
        {
            title: 'AÇÕES',
            links: [
                {
                    id: 'produtos',
                    classe: <Fa.FaArchive />,
                    title: 'Produtos',
                    permissao: user?.tela_produtos == 1 ? true : false,
                    link: '/produtos',
                },
                {
                    id: 'vendas',
                    classe: <Fa.FaShoppingCart />,
                    title: 'Vendas',
                    permissao: false,
                    link: '/vendas',
                },
                {
                    id: 'Locacao',
                    classe: <Fa.FaHandshake />,
                    title: 'Locação',
                    permissao: user?.tela_locacao == 1 ? true : false,
                    link: '/contratos',
                },
                
                
                {
                    id: 'fornecedor',
                    classe: <Fa.FaTruck />,
                    title: 'Fornecedores',
                    permissao: administrador,
                    link: '/fornecedores',

                },
                {
                    id: 'clientes',
                    classe: <Fa.FaUsers />,
                    title: 'Clientes',
                    permissao: user?.tela_clientes == 1 ? true : false,
                    link: '/clientes',
                },
                
                {
                    id: 'pedidos',
                    classe: <Fa.FaProductHunt />,
                    title: 'Pedidos',
                    permissao: false,
                    link: '/pedidos',
                },
            ]
        },
        {
            title: 'ADMIN',
            links: [
                {
                    id: 'administradores',
                    classe: <Fa.FaUserLock />,
                    title: 'Administradores',
                    permissao: false,
                    link: '/#',

                },
                {
                    id: 'usuarios',
                    classe: <Fa.FaUserCircle />,
                    title: 'Usuários do Sistema',
                    permissao: administrador,
                    link: '/usuarios',

                },
                {
                    id: 'financeiro',
                    classe: <Fa.FaDollarSign />,
                    title: 'Financeiro',
                    permissao: user?.tela_financeiro == 1 ? true : false,
                    link: '/financeiro',
                },
                {
                    id: 'compras',
                    classe: <Fa.FaCartPlus />,
                    title: 'Compras',
                    permissao: user?.tela_compras == 1 ? true : false,
                    link: '/#',
                },
                {
                    id: 'pagamentos_custos',
                    classe: <Fa.FaMoneyBill />,
                    title: 'Pagamentos e custos',
                    permissao: user?.tela_pay_custos == 1 ? true : false,
                    link: '/#',

                },
                {
                    id: 'politica_privacidade',
                    classe: <Fa.FaFileAlt />,
                    title: 'Política de privacidade',
                    permissao: false,
                    link: '/#',

                },
                {
                    id: 'sair',
                    classe: <Fa.FaPowerOff />,
                    title: 'Sair',
                    permissao: true,
                    link: '/#',
                },
            ]
        },
    ]

    function mudaFoco(id, link) {
        if (id == 'sair') {
            deslogar()
            return
        }

        let ids = document.querySelectorAll(`.sidebar__menu [id]`)
        //aqui remove todos que tem a classe
        for (const elemento of ids) {
            elemento.classList.remove('active_menu_link');
        }

        if (link == '/#') {
            return AlertaDeMensagem('alerta', 'Essa funcionalidade ainda está sendo desenvolvida', 'Em desenvolvimento', 3000)
        }

        navigate(link)
        setStatusSidebar(false)
    }

    return (
        <div className={statusSidebar ? 'sidebar-responsive' : ''}
            id='sidebar'>
            <div className='sidebar__title' >
                <div className='sidebar__img' >
                    <img src={user?.avatar || logo} alt='logo' />
                    <h1>{user?.nome}</h1>
                </div>

                <Fa.FaTimes
                    onClick={() => setStatusSidebar(false)}
                    className='fa fa-times'
                    id='sidebarIcon'
                    aria-hidden='true'
                />

            </div>
            <div className='sidebar__menu'>
                <div
                    id='home'
                    className={`sidebar__link ${pathname == '/home' ? 'active_menu_link' : ''}`} >
                    <Fa.FaMinusSquare />
                    <a onClick={() => mudaFoco('home', 'home')}>Home</a>
                </div>

                {
                    linksAdm.map((d, ind) =>
                        <>
                            <h2>{d.title}</h2>
                            {
                                d.links.map((e, i) =>

                                    e.permissao &&
                                    <div
                                        id={e.id}
                                        key={e.id}
                                        className={`${e.id != 'sair' ? 'sidebar__link' : 'sidebar__logout'} ${pathname == e.link ? 'active_menu_link' : ''}`}
                                    >
                                        {e.classe}
                                        <a
                                            onClick={() => mudaFoco(e.id, e.link)}
                                        >{e.title}</a>
                                    </div>

                                )
                            }

                        </>
                    )
                }

            </div>
        </div>
    )
}

export default Sidebar;




