import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloProdutos";
import { TbArrowLeft, TbRestore } from "react-icons/tb";
import api from "../../services/apiAxios";
import { AuthContext } from "../../contexts/authContext";
import Pesquisa from "../pesquisa/pesquisa";
import noimage from "../../img/profile.png";
import sem_clientes from "./img/sem-clientes.png";
import { functionCor } from "../../utils/functions";

export default function ComponenteSelecionarProdutos({ setDispositivoSelecionado, setModalLista }) {
  const { setPesquisaAtiva, Spinner } = useContext(AuthContext);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaProdutos2, setListaProdutos2] = useState([]);

  useEffect(() => {
    setPesquisaAtiva(false);
    buscarProdutos();
  }, []);

  function filtraLista(texto) {
    let novoArray = listaProdutos2.filter(
      (e) =>
        e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.descricao.toLowerCase().includes(texto.toLowerCase()) ||
        e.numero_serie.toLowerCase().includes(texto.toLowerCase()) ||
        e.linha_telefonica.toLowerCase().includes(texto.toLowerCase()) ||
        e.iccid.toLowerCase().includes(texto.toLowerCase()) ||
        e.operadora.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_produto.toString().includes(texto)
    );
    setListaProdutos(novoArray);
  }

  async function buscarProdutos() {

    Spinner(true)
    try {

      const res = await api.get(`produtos/disponiveis`)
      if (res.data.status) {
        setListaProdutos(res.data.resultado)
        setListaProdutos2(res.data.resultado)
        Spinner(false)
      } else {
        setListaProdutos([])
        setListaProdutos2([])
        Spinner(false)
      }

    } catch (error) {
      setListaProdutos2([])
      setListaProdutos([])
      Spinner(false)

    }
  }



  return (
    <s.ContainerGeral>
      <s.ContaineRow>
        <div id="container__addIcon">
          <TbArrowLeft
            onClick={() => setModalLista(false)}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
        <Pesquisa
          placeholder="Pesquisa de produtos"
          setValue={filtraLista}
        />
        <div id="container__addIcon">
          <TbRestore
            onClick={() => buscarProdutos()}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
      </s.ContaineRow>
      <s.ContainerScroll>
        {listaProdutos.length > 0 ? (
          listaProdutos.map((c, index) => (
            <div key={c.id_produto}>
              <s.ContainerCard
                cor={functionCor(index)}
                onClick={() => { setDispositivoSelecionado(c); setModalLista(false) }}
                key={c.id_produto}
              >
                <img
                  src={c.foto || noimage}
                  alt="hello"
                />
                <s.Greeting>
                  <h1>
                    {c?.id_produto} - {c?.nome}
                  </h1>
                  <p>Número de série: {c?.numero_serie}</p>
                  <p>Iccid: {c?.iccid}</p>
                </s.Greeting>


              </s.ContainerCard>
            </div>
          )
          )
        ) : (
          <s.DivVaziaImg>
            <img src={sem_clientes} />
            <h1>Nunhum cliente cadastrado</h1>
          </s.DivVaziaImg>
        )}
      </s.ContainerScroll>
    </s.ContainerGeral>
  );
}
