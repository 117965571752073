import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloProdutos'
import api from '../../services/apiAxios';
import { AuthContext } from '../../contexts/authContext';
import * as Fa from "react-icons/fa";
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import MenuAcoes from '../../components/menu_acoes/menu_acoes';
import CompEditarProdutos from './CompEditarProdutos/comp_esditar_produtos';
import CompAdicionarProdutos from './CompNovoProduto/comp_novo_produto';
import noimage from '../../img/noimage.jpg';
import CardListaPadrao from '../../components/card_lista_padrao/card_list';

const objSelectFiltr = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Disponiveis', value: 'Disponível' },
  { label: 'Locados', value: 'Locado' },
]
const strAcao = `Ação não pode ser realizada com um produto vinculado a um contrato`

export default function Produtos() {
  const { Spinner } = useContext(AuthContext)
  const [produtoSelecionado, setProdutoSelecionado] = useState(null)
  const [listaProdutos, setListaProdutos] = useState([])
  const [listaProdutos2, setListaProdutos2] = useState([])
  const [adicionarProduto, setAdicionarProduto] = useState(false)
  const [editarProdutos, setEditarProdutos] = useState([])
  const [tipo, setTipo] = useState(1)
  const [recarrega, setRecarrega] = useState(null)
  const cor = ['#17A398', '#963484', '#064789',]

  useEffect(() => {
    if (produtoSelecionado) {
      for (const key in produtoSelecionado) {
        const valor = produtoSelecionado[key];
        try { document.getElementById(key).value = valor } catch (error) { null }
      }
    }
  }, [produtoSelecionado, recarrega])
  useEffect(() => {
    if (!adicionarProduto) {
      setProdutoSelecionado(null)
      buscarProdutos()
    }
  }, [adicionarProduto])

  function filtraLista(texto) {

    if (texto == 'Todos') {
      setListaProdutos(listaProdutos2)
    } else {
      let novoArray = listaProdutos2.filter(

        e =>
          e?.nome?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.numero_serie?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.codigo_barras?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.modelo_produto?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.descricao?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.nome_categoria?.toLowerCase().includes(texto?.toLowerCase()) ||
          e?.id_produto?.toString().includes(texto?.toLowerCase()) ||
          e?.disponibilidade.toLowerCase()?.toString().includes(texto?.toLowerCase()) ||
          e?.status?.toString().includes(texto?.toLowerCase())
      )
      setListaProdutos(novoArray)
    }
  }
  async function buscarProdutos() {
    Spinner(true)
    setListaProdutos([])
    setListaProdutos2([])

    try {
      const res = await api.get(`/produtos?tipo=${tipo}`)
      if (res.data.status) {
        setListaProdutos(res.data.resultado)
        setListaProdutos2(res.data.resultado)
        Spinner(false)
      } else {
        setListaProdutos([])
        setListaProdutos2([])
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
        Spinner(false)
      }

    } catch (error) {
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }
  }
  async function gravar() {
    let grupos = document.querySelectorAll(`.grupo`)

    let arraysProdutos = []
    let obrigatorio = [
      'nome',
      'custo',
      'numero_serie',
    ]

    for (const e of grupos) {

      let obj = null
      let produtos = document.querySelectorAll(`.valores_${e.value} [name]`)

      for (const elem of produtos) {


        if (obrigatorio.includes(elem.name) && !elem.value) {

          //ação que vai focar no input que não foi preenchido
          try { document.querySelector(`#${elem.name}_${e.value}`).focus() } catch (error) { }

          //Mensagem de retorno ao usuário informando 
          let frase = {
            nome: 'nome do produto.',
            custo: 'custo do produto.',
            numero_serie: 'número de série do produto.',
          }
          AlertaDeMensagem('alerta', `é obrigatório adicionar o ${frase[elem.name]}`)
          return
        }


        if (elem.name != 'id_produto') {

          if (elem.name == 'custo') {
            obj = {
              ...obj,
              [elem.name]: elem.value.replaceAll(',', '.')
            }

          } else {
            obj = {
              ...obj,
              [elem.name]: elem.value
            }

          }

        }
      }
      arraysProdutos.push(obj)


    }

    Spinner(true)

    for (const key in arraysProdutos) {
      const arrayfields = [];
      const arrayvalues = [];
      const inter = [];
      const element = arraysProdutos[key];

      for (const key2 in element) {
        const element2 = element[key2];
        inter.push('?')
        arrayfields.push(key2); // separa fields
        arrayvalues.push(element2); // separa values
      }

      let body = {
        filds: arrayfields,
        valores: arrayvalues,
        inter
      }

      try {
        // a cada vez passada no array de objetos com os produtos, faz a requisição separadamente
        const res = await api.post(`/produtos/insert`, body);
        if (res.data.status) {
          AlertaDeMensagem('sucesso', res.data.mensagem, 'Sucesso!', 5000)
        } else {
          AlertaDeMensagem('alerta', res.data.mensagem, 'Não Permitido', 5000)
        }

      } catch (error) {
        Spinner(false)
        AlertaDeMensagem('aleta', 'erro', 'Não Permitido', 5000)
        console.log('error :>> ', error);
      }
    }
    Spinner(false)
    setAdicionarProduto(false)
  }
  async function gravarEdicaoProdutos() {
    Spinner(true)
    let ids = document.querySelectorAll(`.produtoEdit [name]`)
    let obj = null

    let arrayValores = []
    let update = ''
    let where = ''
    let produtoF = null
    let count = 0
    for (const element of ids) {
      obj = {
        ...obj,
        [element.name]: element.value
      }
      if (element.name != 'id_produto' && element.name != 'estoque_atual') {
        update += (count == 1 ? `${element.name} = ?` : `, ${element.name} = ?`)
        arrayValores.push(element.value)
      } else if (count == 0) {
        if (element.name == 'id_produto') {
          where += `WHERE id_produto = ${element.value}`
        }
      }
      count++
    }
    produtoF = {
      arrayValores,
      update,
      where
    }

    try {

      const resposta = await api.post(`/produtos/updatedinamico`, produtoF)
      if (resposta.data.status) {

      } else {
        console.log('Falhou :>> ');
        console.log('resposta.data :>> ', resposta.data);
      }

    } catch (error) {
      console.log('error :>> ', error);

    }
    zerarTudo()

  }
  function zerarTudo() {
    setEditarProdutos([])
    Spinner(false)
    buscarProdutos()
  }

  let array_botoes = [
    {
      icone: <Fa.FaEdit />,
      title: 'Editar',
      funcao: (e) => e.disponibilidade == 'Locado' ?
        AlertaDeMensagem(
          'alerta',
          `${strAcao}`,
          'ATENÇÃO!',
          3000
        ) :
        setEditarProdutos([e])
    },
    {
      icone: <Fa.FaTrashAlt />,
      title: 'Veículos',
      funcao: (e) => e.disponibilidade == 'Locado' ?
        AlertaDeMensagem(
          'alerta',
          `${strAcao}`,
          'ATENÇÃO!',
          3000
        ) : null
    },
  ]

  return (
    <s.ContainerGeral >

      {
        //Se for não edição de produtos em lotes
        editarProdutos.length == 0 ? (

          //LISTA DE PRODUTOS
          !adicionarProduto ? (
            <s.SubContainerGeral>

              <s.ContaineRow cor={cor[tipo - 1]}>

                <s.BotaoProdutos
                  cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
                  className='container__addIcon'
                  onClick={() => { setTipo(1) }}>
                  <Fa.FaBoxes

                    className='fa fa-times Icon'
                    id='Icon'
                    aria-hidden='true'
                  />
                  <p>PRODUTOS</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                  cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
                  className='container__addIcon'
                  onClick={() => { setAdicionarProduto(true) }}>
                  <Fa.FaPlusSquare

                    className='fa fa-times Icon'
                    id='Icon'
                    aria-hidden='true'
                  />
                  <p>NOVO</p>
                </s.BotaoProdutos>

              </s.ContaineRow>

              <s.Content>

                <MenuAcoes
                  cor={cor[tipo - 1]}
                  editar={false}
                  onClickEditar={() => false}
                  onClickExcluir={() => false}
                  excluir={false}
                  imprimir={null}
                  selPesq={true}
                  onClickImprimir={null}

                  //Pesquisa
                  pesquisa={true}
                  placeholder='Buscar produtos'
                  setValue={filtraLista}
                />

                <div>
                  <s.ContainerPesquisaSel>
                    <s.TextSelect>
                      Disponibilidade
                    </s.TextSelect>
                    <s.ContentSelect>
                      <select
                        onChange={(t) => filtraLista(t.target.value)}
                      >
                        {objSelectFiltr.map((di, inds) =>
                          <option key={inds} value={di.value}>{di.label}</option>
                        )}
                      </select>
                    </s.ContentSelect>
                  </s.ContainerPesquisaSel>

                  <s.ContainerLista>
                    {
                      listaProdutos.length > 0 ? (
                        listaProdutos.map((produto, index) =>
                          <div key={index}>
                            <CardListaPadrao
                              index={index}
                              item={produto}
                              foto={produto.foto || noimage}
                              titulo={`Nome: ${produto.nome}`}
                              detalhe1={`Nº de Série: ${produto.numero_serie}`}
                              detalhe2={`${produto.descricao}`}
                              detalhe3={`${produto.status}`}
                              ativarClick={false}
                              defaultButon={true}
                              array_botoes={array_botoes}
                            />
                          </div>
                        )
                      )
                        :
                        (
                          <p>Nenhum Produto encontrado.</p>
                        )
                    }
                  </s.ContainerLista>

                </div>

              </s.Content>

            </s.SubContainerGeral>)
            :
            (
              //Edição de produtos
              <CompAdicionarProdutos
                // className='elemento'
                cor={cor[tipo - 1]}
                setAdicionarProduto={setAdicionarProduto}
                setRecarrega={setRecarrega}
                gravar={gravar}
                tipo={tipo}
                update={produtoSelecionado?.id_produto ? true : false}
              />)
        )
          : //Edição de produtos em lotes
          (
            <s.ContainerScroll3 >
              <s.ContaineRow2 cor={'#143429'}>
                <s.BotaoProdutos
                  cor={'#143429'}
                  className='container__addIcon'
                  onClick={() => zerarTudo()}>
                  <Fa.FaArrowAltCircleLeft
                    className='fa fa-times Icon'
                    aria-hidden='true'
                  />
                  <p>Voltar</p>
                </s.BotaoProdutos>

                <s.BotaoProdutos
                  cor={'#143429'}
                  className='container__addIcon'
                  onClick={() => { gravarEdicaoProdutos() }}>
                  <Fa.FaSave

                    className='fa fa-times Icon'
                    aria-hidden='true'
                  />
                  <p>Gravar</p>
                </s.BotaoProdutos>

              </s.ContaineRow2>


              {

                editarProdutos.map((e, i) =>
                  <div key={`key_${i + 1}`}>
                    <CompEditarProdutos produto={e} />
                  </div>
                )
              }

            </s.ContainerScroll3>
          )
      }
    </s.ContainerGeral>

  )
}
