import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloveiculos";
import { TbArrowLeft, TbRestore } from "react-icons/tb";
import api from "../../services/apiAxios";
import { AuthContext } from "../../contexts/authContext";
import Pesquisa from "../pesquisa/pesquisa";
import noimage from "../../img/profile.png";
import sem_clientes from "./img/sem-clientes.png";
import { functionCor } from "../../utils/functions";

export default function ComponenteSelecionarVeiculos() {
  const { setPesquisaAtiva, setVeiculosSelecionados, setModalListaVeiculos, Spinner, clienteSelecionado } = useContext(AuthContext);
  const [listaVeiculos, setListaVeiculos] = useState([]);
  const [listaVeiculos2, setListaVeiculos2] = useState([]);

  useEffect(() => {
    setPesquisaAtiva(false);
    buscarVeiculos();
  }, []);

  function filtraLista(texto) {
    let novoArray = listaVeiculos2.filter(
      (e) =>
        e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.numero_serie.toLowerCase().includes(texto.toLowerCase()) ||
        e.nome_categoria.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_produto.toString().includes(texto)
    );
    setListaVeiculos(novoArray);
  }

  async function buscarVeiculos() {

    Spinner(true)
    try {

      const res = await api.get(`clientes/veiculoscliente/${clienteSelecionado.id_cliente}`)
      if (res.data.status) {
        setListaVeiculos(res.data.resultado)
        setListaVeiculos2(res.data.resultado)
        Spinner(false)
      } else {
        setListaVeiculos([])
        setListaVeiculos2([])
        Spinner(false)
      }

    } catch (error) {
      setListaVeiculos2([])
      setListaVeiculos([])
      Spinner(false)

    }
  }

 

  console.log('listaVeiculos :>> ', listaVeiculos);

  return (
    <s.ContainerGeral>
      <s.ContaineRow>
        <div id="container__addIcon">
          <TbArrowLeft
            onClick={() => setModalListaVeiculos(false)}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
        <Pesquisa
          placeholder="Pesquisa de produtos"
          setValue={filtraLista}
        />
        <div id="container__addIcon">
          <TbRestore
            onClick={() => buscarVeiculos()}
            className="fa fa-times Icon"
            id="Icon"
            aria-hidden="true"
          />
        </div>
      </s.ContaineRow>
      <s.ContainerScroll>
        {listaVeiculos.length > 0 ? (
          listaVeiculos.map((c, index) => (
            <div key={c?.id_produto}>
              <s.ContainerCard
                cor={functionCor(index)}
                onClick={() => { setVeiculosSelecionados(c); setModalListaVeiculos(false) }}
                key={c?.id_produto}
              >
                <img
                  src={c?.foto || noimage}
                  alt="hello"
                />
                <s.Greeting>
                  <h1>
                    {c?.marca} - {c?.modelo}
                  </h1>
                  <p>Placa: {c?.placa}</p>
                  <p>Localidade: {c?.localidade} - {c.estado}</p>
                  <p>Cor: {c?.cor}</p>
                  <p>Ano: {c?.ano_fabricacao}/{c.ano_modelo}</p>
                  <p>Chassi: {c?.chassi}</p>
                </s.Greeting>
              </s.ContainerCard>
            </div>
          )
          )
        ) : (
          <s.DivVaziaImg>
            <img src={sem_clientes} />
            <h1>Nunhum Veículo cadastrado</h1>
          </s.DivVaziaImg>
        )}
      </s.ContainerScroll>
    </s.ContainerGeral>
  );
}
