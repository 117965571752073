import { createGlobalStyle } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import BebasNeueRegular from '../fonts/BebasNeue-Regular.ttf';

import RobotoThin from '../fonts/Roboto/Roboto-Thin.ttf';
import RobotoThinItalic from '../fonts/Roboto/Roboto-Thin.ttf';

import RobotoLight from '../fonts/Roboto/Roboto-Light.ttf';
import RobotoLightItalic from '../fonts/Roboto/Roboto-LightItalic.ttf';

import RobotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';
import RobotoRegularItalic from '../fonts/Roboto/Roboto-Italic.ttf';

import RobotoMedium from '../fonts/Roboto/Roboto-Medium.ttf';
import RobotoMediumItalic from '../fonts/Roboto/Roboto-MediumItalic.ttf';

import RobotoBold from '../fonts/Roboto/Roboto-Bold.ttf';
import RobotoBoldItalic from '../fonts/Roboto/Roboto-BoldItalic.ttf';

import RobotoBlack from '../fonts/Roboto/Roboto-Black.ttf';
import RobotoBlackItalic from '../fonts/Roboto/Roboto-BlackItalic.ttf';
import Mr from "../fonts/Montserrat-Regular.ttf";
import { cor_cinza_bordas, cor_primaria } from "./coresPadroes";

export default createGlobalStyle`

    @font-face {
        font-family: 'BebasNeue-Regular';
        src: url(${BebasNeueRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    //Thin 100
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoThin}) format('truetype');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoThinItalic}) format('truetype');
        font-weight: 100;
        font-style: italic;
    }

    //Light 300
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoLightItalic}) format('truetype');
        font-weight: 300;
        font-style: italic;
    }

    //Regular 400
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoRegularItalic}) format('truetype');
        font-weight: 400;
        font-style: italic;
    }

    //Medium 500
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoMediumItalic}) format('truetype');
        font-weight: 500;
        font-style: italic;
    }

    //Bold 700
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoBoldItalic}) format('truetype');
        font-weight: 700;
        font-style: italic;
    }

    //Black 900
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoBlack}) format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoBlackItalic}) format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    @font-face {
            font-family: 'Montserrat';
            src: url(${Mr});
            font-style: normal;
            font-weight: 400;
    }


    *{
        padding: 0;
        margin: 0;
        outline: 0;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${cor_primaria};
            border-radius: 5px;
            border: 3px solid  ${cor_cinza_bordas};
        }
    }

    table {
        border-collapse: collapse;
    }

    .Toastify__toast {
        width: auto;
        h1 {
            font-size: 20px;
        }
            
    }

    html, body , #root, main{
        height: 100vh;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }

    p, h5, h4, h3, h2, h1, select,input{
        margin: 0; 
        font-family: 'Roboto';
    }

    li {
      list-style: none;
      cursor: pointer;
    }

    button{
        background: none;
        border: none;
        cursor: pointer;
    }
    
    button:focus{
       outline: none;
    }

`


