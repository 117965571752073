import styled from "styled-components";
import { cor_primaria } from "../../styles/coresPadroes";

export const ContainerGeral = styled.div`
  max-width: 500px;
  flex: 1;
  border: 1px solid ${cor_primaria};
  border-radius: 10px;
  display: flex;
  align-items: center;
   
  div:hover{
    color: ${cor_primaria} ;
    background-color: #edf2f7 ;
    transition: all .3s ease;
  }

`;

//título 
export const Pesquisa = styled.input`
  min-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  flex: 1;
  outline: none;
  padding-left: 10px;
   
`;

export const Containerlupa = styled.div`
  width: 40px;
  height: 40px;
  color: #edf2f7 ;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${cor_primaria};
  cursor: pointer;
  
`;

export const ContainerFilter = styled.div`
  width: 40px;
  height: 40px;
  color: #edf2f7 ;
  background-color: ${cor_primaria} ;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

`;
