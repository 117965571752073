import * as s from './style_navbar'
import logo_stock from '../../img/logo-stock2.png'
import * as Fa from "react-icons/fa";

const NavBar = ({ statusSidebar, setStatusSidebar }) => {

    return (

        <s.ContainerNavBar>
            <s.ContainerMenuTitulo>
                {
                    !statusSidebar &&
                    <s.NavIcon onClick={() => setStatusSidebar(!statusSidebar)}>
                        <Fa.FaBars
                            onClick={() => setStatusSidebar(!statusSidebar)}
                            className='fa fa-times'
                            id='navbarIcon'
                            aria-hidden='true'
                        />
                    </s.NavIcon>
                }
                <h1>Kweb Stock</h1>
            </s.ContainerMenuTitulo>

            <s.NavbarRight>
                <img
                    style={
                        {
                            width: '40px',
                            height: '40px',
                            objectFit: 'contain',
                            borderRadius: '5px'
                        }
                    }

                    src={logo_stock}
                    alt='avatar'
                />
            </s.NavbarRight>
        </s.ContainerNavBar>
    )
}

export default NavBar;