import styled from 'styled-components';
import { cor_letra_primaria, cor_primaria } from './../../styles/coresPadroes';

// Estilos com Styled Components
export const ContainerNavBar = styled.nav`
  background: #fff;
  background-color: ${cor_primaria};
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid lightgray;
`;
export const ContainerMenuTitulo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  h1 {
    color: ${cor_letra_primaria};
  }

  @media only screen and (max-width: 978px) {

    h1 {
      font-size: 18px;
      
    }

  }

`;

export const NavIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 26px;
    fill: ${cor_letra_primaria};
  }

  @media only screen and (max-width: 978px) {

    svg {
      font-size: 20px;
    }

  }

`;
export const NavbarRight = styled.div`
  flex: .3;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    margin-left: 20px;
    text-decoration: none;

    svg {
      padding: 5px;
      color: #a5aaad;
      font-size: 30px;
      border-radius: 50px;
      background: #fff;
      box-shadow: 2px 2px 2px #d9d9d9, -2px -2px 5px #fff;
    }
  }
`;
