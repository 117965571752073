import styled from "styled-components";

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 70px;
        width: 70px;
        border-radius: 70px;
        object-fit: cover;
        margin-right: 20px;
        background-color: #469cac;
  }
`;
export const ContainerRow = styled.div`
    padding: 20px 35px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    /* box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff ; */
    border-left:solid 5px ${c => c.cor};
`;

export const GreetingCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
    background-color: ${c => c.cor};
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff ;

    h1 {
            font-size: 18px;
            color: #fff;
            margin-bottom: 5px;
    }

    

`;

export const Greeting = styled.div`

    h1 {
            font-size: 18px;
            color: #2e4a66;
            margin-bottom: 5px;
    }
    p {
        font-size: 14px;
        font-weight: 700;
        color: #a5aaad;
    }

`;
export const ContainerOpcoesCard = styled.div`
    padding: 20px 35px;
    width: 98%;
    min-height: 60px;
    background-color: ${c => c.cor};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 5px 13px #ededee, -5px -5px 13px #fff ;

`;

export const ContainerRow2 = styled.div`
    display: flex;
    gap: 20px;

`;
export const Botao = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    gap: 10px;
    margin-left: 10px;
    cursor: pointer;
   
   svg {
    color: #fff;
    width: 23px;
    height: 23px;
   }

   h4 {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
   }

`;