import styled, { keyframes, css } from 'styled-components';
import { cor_primaria } from '../../styles/coresPadroes'

// export const ContainerFundo = styled.div`
//     transition: all ease-in-out .2s;
//     width: ${props => props.onoff == 'true' ? '100vw' : '0'};
//     height: ${props => props.onoff == 'true' ? '100vh' : '0'} ;
//     position: fixed;
//     top: 0px;
//     right: 0px;
//     z-index: 1000;
//     background-color: #0000006b;
//     display: flex;

//     .ocultar{
//         display: none;
//     }

// `;

// export const ContainerClickFechar = styled.div`
//     flex: 1;
//     color: 0000006b;
// `;

// export const ContainerGeral = styled.div`
//     transition: all ease-in-out .2s;
//     width: ${props => props.onoff == 'true' ? '300px' : '0px'};
//     height: ${props => props.onoff == 'true' ? '100vh' : '0px'} ;
//     background-color: #fff;
//     position: relative;
//     top: 0px;
//     right: 0px;
//     z-index: 1000;
//     overflow: scroll;
//     text-align: center;
//     align-items: center;

//     @media print{
//         display: none;
//     }

//     ::-webkit-scrollbar {
//         width: 0px;
//         height: 0px;
//     }
//     hr{
//         width: 96%;
//         align-items: center;
//         text-align: center;
//         align-self: center;
//         border-top: 1px solid #eee;
//     }
// `;

// export const SubContainerGeral = styled.div`
//     height: 100%;
//     overflow:auto;
//     @media print{
//         overflow: hidden;
//     }
// `;

// export const CabecalhoMenu = styled.div`
//     width: 100%;
//     height: auto;
//     background-color: ${cor_primaria};
//     padding: 5px;
    
//     p{
//         color: #fff;
//         text-align: center;
//         font-weight: bold;
//     }
//     `;

// export const ContainerMobile = styled.div`
//     display: flex;
//     font-size: 25px;
//     padding: 4px;
//     justify-content: center;
//     align-items: center;
    
//     p{
//         flex: 6;
//         font-family: 'BebasNeue-Regular';
//         font-size: 22px;
//     }
//     div {
//         flex: 1;
//     }
// `;

// export const ContainerInfoGeral = styled.div`
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: left;
//     background-color: #FFF;
// `;

// export const ContainerGeralBtn = styled.div`
//     width: 100%;
//     display: ${p => p.onoff == 'true' ? 'none' : 'flex'};
//     margin: 4px;

//     .tam250{
//         width: 280px;
//         cursor: pointer;
//     }
// `;

// export const ContainerGeralBtn2 = styled.div`
//     width: 100%;
//     margin: 2px;

//     .tam250{
//         width: 280px;
//         cursor: pointer;
//     }
// `;

// export const ContainerGeralBtnData = styled.div`
//     width: 90%;
//     display: flex;
//     flex-direction: row;
//     justify-content: left;
//     padding: 4px;
//     cursor: pointer;
//     border-radius: 8px;
// `;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ContainerFiltro = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000a5;
  z-index: 101;
  animation: ${({ fecha }) => fecha ? css`${fadeOut} 0.8s ease-out` : css`${fadeIn} 0.8s ease-out`};
  opacity: ${({ fecha }) => (fecha ? 0 : 1)};
`;

export const ContentFilt = styled.div`
position: absolute;
width: 350px;
  right: 0; 
  top: 0; 
  height: 80vh;
`;
export const ContTopo = styled.div`
display: flex;
min-height: 35px;
background-color: ${cor_primaria};
justify-content: space-between;
padding: 10px;

`;
export const TituFiltro = styled.div`

color: #fff;
font-size: 12pt;
font-weight: 700;
display: flex;
align-items: center;
`;
export const BotX = styled.div`

font-size: 15pt;
font-weight: bold;
color: #fff;
padding-right: 5px;
cursor: pointer;
`;
export const ContentAbaixo = styled.div`
display: flex;
background-color: #fff;
height: 100vh;
position: relative;
`;


export const ContCheck1 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  label {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
  }

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;
export const ContCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  label {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
  }

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;
export const ContSelectAn = styled.div`
  select {
    width: 100%;
    padding: 8px 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
    margin: 5px 0px 15px 0px;
    &:hover, &:focus {
      border-color: ${cor_primaria}; 
      outline: none;
    }
  }

`;
export const LabelDt = styled.div`
font-size: 10pt;
text-align: center;
color: ${cor_primaria};
font-weight: 700;
margin-top: 20px 0px;
  `;

export const ContSelectDat = styled.div`

  input {
    margin-top: 5px;
    width: 100%;
    padding: 8px 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
    &:hover, &:focus {
      border-color: ${cor_primaria}; /* Cor de destaque ao focar ou passar o mouse */
      outline: none;
    }
  }
margin-bottom: 25px;
`;
export const ContSelectDat2 = styled.div`

  input {
    margin-top: 5px;
    width: 100%;
    padding: 8px 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
    &:hover, &:focus {
      border-color: ${cor_primaria}; /* Cor de destaque ao focar ou passar o mouse */
      outline: none;
    }
  }

`;
export const ContSelect = styled.div`
  select {
    width: 100%;
    padding: 8px 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
    margin: 5px 0px;
    &:hover, &:focus {
      border-color: ${cor_primaria}; /* Cor de destaque ao focar ou passar o mouse */
      outline: none;
    }
  }
  input {
    margin-top: 5px;
    width: 100%;
    padding: 8px 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
    &:hover, &:focus {
      border-color: ${cor_primaria}; /* Cor de destaque ao focar ou passar o mouse */
      outline: none;
    }
  }

`;
export const FiltroContainerbass = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #f9f9f9; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
  height: 100vh;
`;
export const ContentPs = styled.div`
height: 80vh;
position: relative;
`;
export const BotaoPesquisa = styled.div`
  display: flex;
  width: 100%;
  background-color: ${cor_primaria};
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 35px;
  align-items: center;
  cursor: pointer;
  div {
    font-size: 12pt;
    font-weight: 700;
    text-align: center;
    width: 100%;
    color: #fff;
  }
  :hover {
    border-radius: 5px;
    font-size: 14pt;
  }
  border-radius: 5px;
`;