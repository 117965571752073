import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelReaderFornecedores = ({ retorno }) => {

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      montaArrayObjetos(jsonData)
    };

    reader.readAsArrayBuffer(file);
  };



  function montaArrayObjetos(array) {
    // console.log('array :>> ', array);
    const chaves = {
      id_fornecedor: "id_fornecedor",
      nome: "nome",
      fantasia: "fantasia",
      tipo_pessoa: "tipo_pessoa",
      cpf: "cpf",
      rg: "rg",
      cnpj: "cnpj",
      crt: "crt",
      contribuintes: "contribuintes",
      inscricao_estadual: "inscricao_estadual",
      inscricao_estadual_isento: "inscricao_estadual_isento",
      inscricao_municipal: "inscricao_municipal",
      data_nascimento: "data_nascimento",
      fone: "fone",
      email: "email",
      profissao: "profissao",
      ativo: "ativo",
      codigo: "codigo",
      endereco: "endereco",
      numero: "numero",
      complemento: "complemento",
      bairro: "bairro",
      cep: "cep",
      cidade: "cidade",
      uf: "uf",
      contatos: "contatos",
      celular: "celular",
      web_site: "web_site",
      ie_isento: "ie_isento",
      situacao: "situacao",
      email_nfe: "email_nfe",
      limite_credito: "limite_credito",
      cliente_desde: "cliente_desde",
      regime_tributario: "regime_tributario",
      proxima_visita: "proxima_visita"
    };

    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      const el = array[i];
      let newObj = {};

      for (const key in el) {
        const valor = el[key];
        const chaveBlingMin = key.replace(/[^a-z0-9]/gi, "").toLowerCase();
      
        // as chaves que o bling exporta, não estão no padrão aceitavel então transformei
        let chaveKweb;
        if (chaveBlingMin === "id") chaveKweb = "id_fornecedor";
        else if (chaveBlingMin == "cdigo") chaveKweb = "codigo";
        else if (chaveBlingMin == "nome") chaveKweb = "nome";
        else if (chaveBlingMin == "fantasia") chaveKweb = "fantasia";
        else if (chaveBlingMin == "endereo") chaveKweb = "endereco";
        else if (chaveBlingMin == "numero") chaveKweb = "numero";
        else if (chaveBlingMin == "complemento") chaveKweb = "complemento";
        else if (chaveBlingMin == "bairro") chaveKweb = "bairro";
        else if (chaveBlingMin == "cep") chaveKweb = "cep";
        else if (chaveBlingMin == "cidade") chaveKweb = "cidade";
        else if (chaveBlingMin == "uf") chaveKweb = "uf";
        else if (chaveBlingMin == "contatos") chaveKweb = "contatos";
        else if (chaveBlingMin == "fone") chaveKweb = "fone";
        else if (chaveBlingMin == "celular") chaveKweb = "celular";
        else if (chaveBlingMin == "email") chaveKweb = "email";
        else if (chaveBlingMin == "website") chaveKweb = "web_site";
        else if (chaveBlingMin == "tipopessoa") chaveKweb = "tipo_pessoa";
        else if (chaveBlingMin === "cnpjcpf") {
          // Verifica se é CPF ou CNPJ pelo comprimento do valor
          if (valor.length == 14) {
            newObj["cpf"] = valor;  // Valor é CPF
          } else if (valor.length == 18) {
            newObj["cnpj"] = valor; // Valor é CNPJ
          }
          continue; // Pule para a próxima chave
        }
        else if (chaveBlingMin == "ierg") chaveKweb = "rg";
        else if (chaveBlingMin == "ieisento") chaveKweb = "ie_isento";
        else if (chaveBlingMin == "situacao") chaveKweb = "situacao";
        else if (chaveBlingMin == "emailparaenvionfe") chaveKweb = "email_nfe";
        else if (chaveBlingMin == "limitedecrdito") chaveKweb = "limite_credito";
        else if (chaveBlingMin == "clientedesde") chaveKweb = "cliente_desde";
        else if (chaveBlingMin == "regimetributrio") chaveKweb = "regime_tributario";
        else if (chaveBlingMin == "prximavisita") chaveKweb = "proxima_visita";
        else chaveKweb = chaves[chaveBlingMin];


        if (chaveKweb) {
          newObj[chaveKweb] = valor;
        }
      }

      // Garante que todas as chaves do modelo `chaves` estão presentes em `newObj`, usando null como padrão
      for (const key in chaves) {
        if (!(chaves[key] in newObj)) {
          newObj[chaves[key]] = null;
        }
      }

      newArray.push(newObj);
    }


    retorno(newArray);
  }


  return (
    <div>
      <h1>Leitor de Arquivo Excel</h1>
      <input id='file' type="file" accept=".xlsx, .xls" onChange={handleFileUpload} /> <br />
    </div>
  );
};

export default ExcelReaderFornecedores;
