import { array_cores_cards } from "../styles/coresPadroes";
export function functionCor(index) {
    if (index % 4 == 0) {
        return array_cores_cards[0];
    } else if (index % 4 == 1) {
        return array_cores_cards[1];
    } else if (index % 4 == 2) {
        return array_cores_cards[2];
    } else if (index % 4 == 3) {
        return array_cores_cards[3];
    }
}