import styled from 'styled-components';

export const ContainerInputFloat = styled.div`
    font-family: sans-serif;
    width: 100%;
  
    .label-float{
        position: relative;
        padding-top: ${e => e.axecao == 'true' ? '0px' : '13px'} ;
        text-align-last: left;
    }
    
    .label-checkbox {
        text-align: left;
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
        min-width: 200px;
        padding: 5px 20px;
        font-size: 14px;
        transition: all .1s linear;
    }

    .label-checkbox input[type=checkbox]{
        width: 24px;
        height: 24px;
        color: #469CAC;
        cursor: pointer;
    }
    
    .label-float input{
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
        min-width: 200px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all .1s linear;
    }
    
    .label-float select{
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
        min-width: 200px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all .1s linear;
    }
    
    .label-float textarea{
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
        min-width: 200px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all .1s linear;
    }
    
    .label-float input:focus{
        border: 2px solid #469CAC;
    }

    .label-float select:focus{
        border: 2px solid #469CAC;
    }
    .label-float textarea:focus{
        border: 2px solid #469CAC;
    }
    .label-checkbox input:focus{
        border: 2px solid #469CAC;
    }

    
    .label-float input::placeholder{
        color:transparent;
    }
    .label-float select::placeholder{
        color:transparent;
    }
    .label-float textarea::placeholder{
        color:transparent;
    }

    
    .label-float label{
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all .1s linear;
        
        
        background-color: white;
        padding: 5px;
        box-sizing: border-box;
    }
    
    .label-checkbox legend{
        pointer-events: none;
        text-align: left;
        padding: 5px;
        color: #469CAC;
    }
    
    .label-float input:required:invalid + label{
        color: red;
    }
    .label-float select:required:invalid + label{
        color: red;
    }
    .label-float textarea:required:invalid + label{
        color: red;
    }


    .label-float input:required:invalid + label:before{
        content: '*';
    }
    .label-float select:required:invalid + label:before{
        content: '*';
    }
    .label-float textarea:required:invalid + label:before{
        content: '*';
    }

    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label{
        font-size: 13px;
        top: 0;
        color: #469CAC;
    }
    .label-float select:focus + label,
    .label-float select:not(:placeholder-shown) + label{
        font-size: 13px;
        top: 0;
        color: #469CAC;
    }
    .label-float textarea:focus + label,
    .label-float textarea:not(:placeholder-shown) + label{
        font-size: 13px;
        top: 0;
        color: #469CAC;
    }

    #container__input__button{
        height: 63px;
        display: flex;
        align-items: flex-end;

        input {
            height: 50px;
            border: 1px solid lightgrey;
            transition: all .1s linear;
            width: 100%;
            padding: 15px 20px;
            background-color: #fff;
            cursor: pointer;
            color: #000;
            font-size: 16px;
            border-radius: 5px;
            outline: none;

            min-width: 200px;
            padding: 15px 20px;
            font-size: 16px;
        }
    }


    .real_{
        text-align: right;
    }

    input[type=date]{
        height: 50px;
    }
    input[type=datetime-local]{
        height: 50px;
    }
  
`
