import Axios from 'axios';
const api = Axios.create({
    baseURL: process.env.NODE_ENV === 'development' ?
        // "http://localhost:2002"
        // "https://stock.kwebsistemas.com"
        // "http://192.168.5.163:2002"
        // "http://192.168.5.167:2002"
        // "http://localhost:2002"
        "http://10.0.0.162:2002"
        :
        "https://stock.kwebsistemas.com"
});

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem('@token') || '';
        if (token) {
            config.headers.token = `${token}`;
        }
        return config;
    } catch (err) {
        alert(err);
    }
});

export default api;