import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 400px;
`;

export const CardHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
`;

export const ContractDetail = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
`;

export const DetailLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

export const StatusBadge = styled.span`
  display: inline-block;
  padding: 5px 10px;
  color: white;
  background-color: ${props => (props.status === 'ativo' ? '#4CAF50' : '#f44336')};
  border-radius: 20px;
  margin-top: 10px;
`;