import styled from "styled-components";

export const ContainerGeral = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
  padding: 25px;
`;
export const SubContainerGeral = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
//card 

export const ContainerCard = styled.div`
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 5px 0px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};
`;

export const ContainerDados = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }
`;
export const ContainerBotoes = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
`;


export const Greeting = styled.div`
  h1 {
    font-size: 18px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
`;
export const BotoesCard = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  svg {
    color: ${(p) => p.cor};
  }
`;
export const ContCompCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;
export const Text = styled.div`
    color: ${(p) => p.cor};
    font-size: 13px;
`;


export const ContaineRow2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  #container__voltaIcon {
    width: 33px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  #container__addIcon {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  .Icon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .voltar {
    color: #a5aaad;
  }
`;
