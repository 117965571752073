import * as s from './estiloDetalhes'

export default function DetalhesCard({ e }) {
    return (
        <s.ModalWrapper>

            <s.CardHeader>DETALHES DO CONTRATO</s.CardHeader>
            <s.ContractDetail>
                <s.DetailLabel>Nome: </s.DetailLabel>{e?.nome} {e?.sobrenome}
            </s.ContractDetail>
            <s.ContractDetail>
                <s.DetailLabel>Início: </s.DetailLabel>{e?.data_inicio}
            </s.ContractDetail>
            <s.ContractDetail>
                <s.DetailLabel>Final: </s.DetailLabel>{e?.data_fim}
            </s.ContractDetail>
            <s.ContractDetail>
                <s.DetailLabel>Valor Mensal: </s.DetailLabel>R$ {e?.valor_mensal}
            </s.ContractDetail>
            <s.ContractDetail>
                <s.DetailLabel>Status: </s.DetailLabel>
                <s.StatusBadge status={e?.status}>{e?.status}</s.StatusBadge>
            </s.ContractDetail>

        </s.ModalWrapper>
    );
};