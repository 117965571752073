import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloLista";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import Pesquisa from "../../pesquisa/pesquisa";

import * as Fa from "react-icons/fa";
import AlertaDeMensagem from "../../AlertaMensagem/AlertaDeMensagem";
import CardEnderecos from "../cardEndereco/enderecos";
import { functionCor } from "../../../utils/functions";
import CardListaPadrao from "../../card_lista_padrao/card_list";


export default function ListaEnderecos({ id, tipo }) {
    const { Spinner } = useContext(AuthContext);
    const [listaEnderecos, setListaEnderecos] = useState([]);
    const [listaEnderecos2, setListaEnderecos2] = useState([]);
    const [addEditEndereco, setAddEditEndereco] = useState(null);


    useEffect(() => {
        if (addEditEndereco == null) {
            setListaEnderecos([])
            setListaEnderecos2([])
            buscarListaEnderecos(id)
        }
    }, [addEditEndereco]);

    async function buscarListaEnderecos(id) {
        let consulta;
        Spinner(true);
        try {
            if (tipo == 'fornecedor') {
                consulta = await api.get(`fornecedores/endereco/${id}`)
            } else {
                consulta = await api.get(`/clientes/enderecocliente/${id}`)
            }

            if (consulta.data.status) {
                setListaEnderecos(consulta.data.resultado)
                setListaEnderecos2(consulta.data.resultado)
                Spinner(false)
            } else {
                AlertaDeMensagem(
                    "alerta",
                    `Este ${tipo == 'fornecedor' ? 'fornecedor' : 'cliente'} não possui nunhum endereço cadastrado `,
                    "ATENÇÃO!",
                    4000
                );
                Spinner(false)
            }
        } catch (error) {
            Spinner(false)
            console.log(error);
        }
    }

    function filtraLista(texto) {
        let novoArray = listaEnderecos2.filter(
            (e) =>
                e.logradouro.toLowerCase().includes(texto.toLowerCase()) ||
                e.complemento.toLowerCase().includes(texto.toLowerCase()) ||
                e.cidade.toLowerCase().includes(texto.toLowerCase()) ||
                e.bairro.toLowerCase().includes(texto.toLowerCase()) ||
                e.estado.toString().includes(texto) ||
                e.id_endereco.toString().includes(texto)
        );
        setListaEnderecos(novoArray);
    }

    async function excluirEndereco(id) {
        let consulta;
        Spinner(true);
        try {
            if (tipo == 'fornecedor') {
                console.log('Excluiu Endereço Fornecedor');
                return
            } else {
                consulta = await api.delete(`/clientes/excluirendereco/${id}`)
            }

            if (consulta.data.status) {
                buscarListaEnderecos(id)
                AlertaDeMensagem(
                    "sucesso",
                    "Endereço excluído com sucesso!",
                    "SUCESSO!",
                    4000
                );
            } else {
                AlertaDeMensagem(
                    "alerta",
                    "Não foi possível excluir esse endereço",
                    "ATENÇÃO!",
                    4000
                );
                Spinner(false)
            }
        } catch (error) {
            Spinner(false)
            console.log(error);
        }

    }

    let array_botoes = [
        {
          icone: <Fa.FaEdit />,
          title: 'Editar',
          funcao: (e) => setAddEditEndereco(e)
        },
        {
          icone: <Fa.FaTrash />,
          title: 'Excluir',
          funcao: (e) => excluirEndereco(e?.id_endereco)
        },
      ]


    return (
        <>
            {
                addEditEndereco == null ?
                    <s.ContainerGeral>
                        <s.ContaineRow>
                            <Pesquisa
                                placeholder="Pesquisa de Endereços"
                                setValue={filtraLista}
                            />
                            <div id="container__addIcon">
                                <Fa.FaRegPlusSquare
                                    onClick={() => {
                                        setAddEditEndereco({ id: id })
                                    }}
                                    className="fa fa-times Icon"
                                    id="Icon"
                                    aria-hidden="true"
                                />
                            </div>
                        </s.ContaineRow>
                        <s.ContainerScroll>
                            {listaEnderecos.map((item, index) =>
                                <div key={index}>
                                    <CardListaPadrao
                                        index={index}
                                        item={item}
                                        titulo={`${index + 1} - ${item.logradouro}, ${item.numero}`}
                                        detalhe1={`Bairro: ${item.bairro} - Cidade: ${item.cidade}`}
                                        detalhe2={`Estado: ${item.estado} - País: ${item.pais}`}
                                        ativarClick={true}
                                        defaultButon={false}
                                        array_botoes={array_botoes}
                                    />
                                </div>

                            )}
                        </s.ContainerScroll>
                    </s.ContainerGeral>
                    :
                    <CardEnderecos
                        dados={addEditEndereco}
                        setAddEditEndereco={setAddEditEndereco}
                        buscarListaEnderecos={buscarListaEnderecos}
                        tipo={tipo}
                    />

            }


        </>
    );
}