import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloClientes";
import api from "./../../services/apiAxios";
import { AuthContext } from "../../contexts/authContext";
import Pesquisa from "../../components/pesquisa/pesquisa";
import * as Fa from "react-icons/fa";
import InputKweb from "../../components/InputPersonalizado/input_personalizavel";
import axios from "axios";
import AlertaDeMensagem from "../../components/AlertaMensagem/AlertaDeMensagem";
import noimage from "../../img/profile.png";
import sem_clientes from "./img/sem-clientes.png";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import ListaVeiculos from "../veiculos/lista/listaVeiculos";
import ListaEnderecos from "../../components/Enderecos/lista/listaEndereco";
import CardListaPadrao from "../../components/card_lista_padrao/card_list";


export default function Clientes() {
  const navigate = useNavigate()
  const { user, setPesquisaAtiva, Spinner } = useContext(AuthContext);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaClientes2, setListaClientes2] = useState([]);
  const [adicionarCliente, setAdicionarCliente] = useState(false);
  const [imgUsuarioSalvar, setImgUsuarioSalvar] = useState(null);
  const cor = ['#17A398', '#963484', '#064789',]
  const [tipo, setTipo] = useState(1)
  const [cep, setCep] = useState("");


  useEffect(() => {
    if (cep.replaceAll("-", "").length == 8) {
      buscarCep(cep);
    }
  }, [cep]);

  //entra aqui quando é edição de cliente
  useEffect(() => {
    if (clienteSelecionado) {
      setImgUsuarioSalvar(clienteSelecionado.foto_cliente);
      for (const key in clienteSelecionado) {
        const valor = clienteSelecionado[key];
        try {
          document.getElementById(key).value = valor;
        } catch (error) {
          null;
        }
      }
    }

  }, [clienteSelecionado, tipo]);

  useEffect(() => {
    if (!adicionarCliente) {
      setClienteSelecionado(null);
      setImgUsuarioSalvar(null);
    }
  }, [adicionarCliente]);

  async function buscarCep(cep) {
    let url = `https://viacep.com.br/ws/${cep}/json`;

    // Função assíncrona para fazer a requisição

    try {
      // Faz a requisição GET utilizando Axios e aguarda a resposta
      const response = await axios.get(url);
      // Manipula os dados recebidos
      const dados = response.data;
      if (dados) {
        if (dados.erro) {
          document.querySelector(`#logradouro`).value = "";
          document.querySelector(`#cidade`).value = "";
          document.querySelector(`#estado`).value = "";
          document.querySelector(`#bairro`).value = "";
          return AlertaDeMensagem(
            "alerta",
            "Verifique o cep digitado e tente novamente.",
            "CEP INVÁLIDO",
            4000
          );
        }

        document.querySelector(`#logradouro`).value = dados.logradouro;
        document.querySelector(`#cidade`).value = dados.localidade;
        document.querySelector(`#estado`).value = dados.uf;
        document.querySelector(`#bairro`).value = dados.bairro;
      } else {
        AlertaDeMensagem(
          "alerta",
          "Falha na consulta do endereço.",
          "CEP INVÁLIDO",
          4000
        );
      }
      // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
    } catch (error) {
      // Captura e trata qualquer erro ocorrido durante a requisição
      AlertaDeMensagem(
        "alerta",
        "Erro ao obter os dados do CEP:",
        "Erro",
        4000
      );
      console.error("Erro ao obter os dados do CEP:", error);
    }
  }

  function editarCliente(cliente) {
    setClienteSelecionado(cliente);
    setAdicionarCliente(true);
  }

  useEffect(() => {
    setPesquisaAtiva(false);
    buscarClientes();
  }, []);

  function filtraLista(texto) {
    let novoArray = listaClientes2.filter(
      (e) =>
        e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.sobrenome.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_cliente.toString().includes(texto)
    );
    setListaClientes(novoArray);
  }
  async function buscarClientes() {
    Spinner(true)
    try {
      const res = await api.get(`/clientes`);
      if (res.data.status) {
        setListaClientes(res.data.resultado);
        setListaClientes2(res.data.resultado);
        Spinner(false)
      } else {
        Spinner(false)
        setListaClientes([]);
        setListaClientes2([]);
        AlertaDeMensagem(
          "alerta",
          "Não existe nenhum cliente cadastrado, faça seu primeiro cadastro",
          "ATENÇÃO",
          4000
        );
      }
    } catch (error) {
      Spinner(false)
      console.log("error :>> ", error);
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }
  }
  async function gravar() {
    let elementos = document.querySelectorAll(`.valores [name]`);
    let fildsvalores = null
    let obrigatorio = []

    fildsvalores = { foto_cliente: imgUsuarioSalvar };
    obrigatorio = [
      "nome",
      "sobrenome",
      "cpf",
      "data_nascimento",
      "profissao",
      "email",
      "fone"
    ];



    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value;
      fildsvalores = {
        ...fildsvalores,
        [fild.name]: valor,
      };
    }

    for (const key of obrigatorio) {
      if (!fildsvalores[key]) {
        AlertaDeMensagem("alerta", `é obrigatório passa o ${key}`);
        return;
      }
    }

    try {

      const res = await api.post(`/clientes/insertupdate`, fildsvalores);
      if (res.data.status) {
        setAdicionarCliente(false);
        buscarClientes();
        AlertaDeMensagem(
          "sucesso",
          "Ação gravada com sucesso",
          "Sucesso!",
          4000
        );
      } else {
        AlertaDeMensagem(
          "alerta",
          "Ação não pode ser gravada, tente novamente",
          "ATENÇÃO!",
          4000
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }



  }

  //upload imagem
  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0], // É o arquivo da imagem que será redimensionada.
          100, // É o maxWidth da nova imagem redimensionada.
          100, // É o maxHeight da nova imagem redimensionada.
          "JPEG", // É o compressFormat de a nova imagem redimensionada.
          100, // É a qualidade da nova imagem redimensionada.
          0, // É o grau de rotação no sentido horário a ser aplicado à imagem enviada.
          (uri) => {
            // É a função callBack do novo URI de imagem redimensionado.
            setImgUsuarioSalvar(uri);
          },
          "base64", // É o tipo de saída da nova imagem redimensionada.
          100, // É o minWidth da nova imagem redimensionada.
          100 // É o minHeight da nova imagem redimensionada.
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  /**
   * id_cliente, nome, sobrenome, cpf, genero, data_nascimento, logradouro, numero, cidade, estado, cep, pais, fone, email, profissao, data_cadastro, ativo
   */

  //fildes dos inputs
  const filds = [
    {
      linha: [
        {
          label: "Id",
          value: "id_cliente",
          tipo: "text",
          select: [],
          clas: "",
          clas2: clienteSelecionado != null ? "" : "ocultar",
        },
        {
          label: "Nome",
          value: "nome",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Sobrenome",
          value: "sobrenome",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "CPF",
          value: "cpf",
          tipo: "text",
          select: [],
          clas: "cpf_",
          clas2: "",
        },
      ],
      id: "1",
    },
    {
      linha: [
        {
          label: "Gênero",
          value: "genero",
          tipo: "select",
          select: [
            { label: "Masculino", value: 1 },
            { label: "Feminino", value: 0 },
          ],
          clas: "",
          clas2: "",
        },
        {
          label: "Data Nas",
          value: "data_nascimento",
          tipo: "date",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Profissão",
          value: "profissao",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Ativo",
          value: "ativo",
          tipo: "select",
          select: [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
          ],
          clas: "",
          clas2: "",
        },
      ],
      id: "2",
    },
    {
      linha: [
        {
          label: "E-mail",
          value: "email",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Fone",
          value: "fone",
          tipo: "text",
          select: [],
          clas: "fone_",
          clas2: "",
        },
      ],
      id: "4",
    },
  ]


  let array_botoes = [
    {
      icone: <Fa.FaEdit />,
      title: 'Editar',
      funcao: (e) => editarCliente(e)
    },
    {
      icone: <Fa.FaCar />,
      title: 'Veículos',
      funcao: (e) => navigate(`/listaVeiculos/${e.id_cliente}`)
    },
  ]

  console.log('listaClientes :>> ', listaClientes);

  return (
    <s.ContainerGeral>
      {!adicionarCliente ? (
        <>

          <s.ContaineRow>
            <Pesquisa
              placeholder="Pesquisa de cliente"
              setValue={filtraLista}
            />

            <div id="container__addIcon">
              <Fa.FaUserPlus
                onClick={() => {
                  setAdicionarCliente(true);
                }}
                className="fa fa-times Icon"
                id="Icon"
                aria-hidden="true"
              />
            </div>
          </s.ContaineRow>

          {
            listaClientes.length > 0 ? (
              <s.ContainerScroll>
                {
                  listaClientes.map((item, index) => (
                    <div key={index}>
                      <CardListaPadrao
                        index={index}
                        item={item}
                        foto={item?.foto_cliente}
                        titulo={`${item?.nome} ${item?.sobrenome}`}
                        detalhe1={`Código do cliente ${item?.id_cliente}`}
                        detalhe2={`Profissão: ${item?.profissao}`}
                        ativarClick={false}
                        defaultButon={true}
                        array_botoes={array_botoes}
                      />
                    </div>
                  ))
                }
              </s.ContainerScroll>
            ) : (
              <s.DivVaziaImg>
                <img src={sem_clientes} />
                <h1>Nunhum cliente cadastrado</h1>
              </s.DivVaziaImg>
            )
          }

        </>
      ) : (

        <s.SubContainerGeral>
          <s.ContaineRow2 cor={cor[tipo - 1]}>

            <s.BotaoProdutos
              cor={'#A5ABBA'}
              className='container__addIcon'
              onClick={() => { setTipo(1); setAdicionarCliente(false) }}>
              <Fa.FaArrowAltCircleLeft

                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Voltar</p>
            </s.BotaoProdutos>

            <s.BotaoProdutos
              cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
              className='container__addIcon'
              onClick={() => { setTipo(1) }}>
              <Fa.FaUserAlt

                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Cliente</p>
            </s.BotaoProdutos>

            <s.BotaoProdutos
              cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
              className='container__addIcon'
              onClick={() => { clienteSelecionado ? setTipo(2) : AlertaDeMensagem('alerta', 'É necessário gravar antes os dados do cliente!', 'Obrigatório', 4000) }}>
              <Fa.FaSearchLocation
                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Endereços</p>
            </s.BotaoProdutos>
            <s.BotaoProdutos
              cor={tipo == 3 ? cor[tipo - 1] : '#A5ABBA'}
              className='container__addIcon'
              onClick={() => clienteSelecionado ? setTipo(3) : AlertaDeMensagem('alerta', 'É necessário gravar antes os dados do cliente!', 'Obrigatório', 4000)}>
              <Fa.FaCar
                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Veículos</p>
            </s.BotaoProdutos>

            {
              tipo == 1 &&
              <s.BotaoProdutos
                cor={'#65D178'}
                className='container__addIcon'
                onClick={() => { gravar(); }}>
                <Fa.FaHandshake
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>GRAVAR</p>
              </s.BotaoProdutos>
            }


          </s.ContaineRow2>

          {
            tipo == 1 ?
              <s.ContainerRowGrupo>
                <s.BotaoInsertImg
                  onClick={() => document.querySelector("#foto_cliente").click()}
                >
                  <img
                    id="logo"
                    src={
                      imgUsuarioSalvar
                        ? imgUsuarioSalvar
                        : clienteSelecionado?.foto_cliente != null
                          ? clienteSelecionado?.foto_cliente
                          : noimage
                    }
                  />
                  <input
                    type="file"
                    className="inputs"
                    id="foto_cliente"
                    onChange={(e) => {
                      fileChangedHandler(e);
                    }}
                    style={{ display: "none" }}
                  />
                </s.BotaoInsertImg>

                <s.ContainerScroll>
                  {filds.map((l, index) => {
                    return (
                      <s.ContainerInputPesq
                        key={index}
                        className="valores">
                        {l.linha.map((e, i) => {
                          return (
                            <s.ContainerInputFi
                              key={i}
                              className={`${e.clas2}`}
                            >
                              <InputKweb
                                className={`salvarbd_ limparinput_ ${e.clas}`}
                                titulo={e.label}
                                type={e.tipo}
                                id={e.value}
                                name={e.value}
                                style={{ width: "100%" }}
                                onChange={(text) =>
                                  e.value == "cep" ? setCep(text.target.value) : {}
                                }
                              >
                                {e.select.map((op, index) => (
                                  <option key={index} value={op.value}>
                                    {op.label}
                                  </option>
                                ))}
                              </InputKweb>
                            </s.ContainerInputFi>
                          );
                        })}
                      </s.ContainerInputPesq>
                    );
                  })}
                </s.ContainerScroll>
              </s.ContainerRowGrupo>
              : tipo == 2 ?
                <ListaEnderecos id={clienteSelecionado.id_cliente} tipo={'cliente'} />

                : tipo == 3 &&
                <ListaVeiculos id_cliente2={clienteSelecionado.id_cliente} />
          }
        </s.SubContainerGeral>
      )
      }
    </s.ContainerGeral>
  );
}



