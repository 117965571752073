import React, { useContext, useEffect, useState } from "react";
import * as s from "./estiloEnderecos";
import api from "../../../services/apiAxios";
import { AuthContext } from "../../../contexts/authContext";
import * as Fa from "react-icons/fa";
import InputKweb from "../../InputPersonalizado/input_personalizavel";
import AlertaDeMensagem from "../../AlertaMensagem/AlertaDeMensagem";
import axios from "axios";

//fildes dos inputs
const filds = [
  {
    linha: [
      {
        label: "Id do endereço",
        value: "id_endereco",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "ocultar",
      },
      {
        label: "Cep",
        value: "cep",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Logradouro",
        value: "logradouro",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Número",
        value: "numero",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },

    ],
    id: "1",
  },
  {
    linha: [
      {
        label: "Bairro",
        value: "bairro",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Cidade",
        value: "cidade",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "Estado",
        value: "estado",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      },
      {
        label: "País",
        value: "pais",
        tipo: "text",
        select: [],
        clas: "",
        clas2: "",
      }

    ],
    id: "2",
  },

];

export default function CardEnderecos({ dados, setAddEditEndereco, buscarListaEnderecos, tipo }) {

  // tipo = fornecedor || cliente 

  const { Spinner } = useContext(AuthContext);
  const [cep, setCep] = useState("");



  useEffect(() => {
    if (cep.replaceAll("-", "").length == 8) {
      buscarCep(cep);
    }
  }, [cep]);

  async function buscarCep(cep) {
    let url = `https://viacep.com.br/ws/${cep}/json`;

    // Função assíncrona para fazer a requisição

    try {
      // Faz a requisição GET utilizando Axios e aguarda a resposta
      const response = await axios.get(url);
      // Manipula os dados recebidos
      const dados = response.data;
      if (dados) {
        if (dados.erro) {
          document.querySelector(`#logradouro`).value = "";
          document.querySelector(`#cidade`).value = "";
          document.querySelector(`#estado`).value = "";
          document.querySelector(`#bairro`).value = "";
          return AlertaDeMensagem(
            "alerta",
            "Verifique o cep digitado e tente novamente.",
            "CEP INVÁLIDO",
            4000
          );
        }

        document.querySelector(`#logradouro`).value = dados.logradouro;
        document.querySelector(`#cidade`).value = dados.localidade;
        document.querySelector(`#estado`).value = dados.uf;
        document.querySelector(`#bairro`).value = dados.bairro;
      } else {
        AlertaDeMensagem(
          "alerta",
          "Falha na consulta do endereço.",
          "CEP INVÁLIDO",
          4000
        );
      }
      // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
    } catch (error) {
      // Captura e trata qualquer erro ocorrido durante a requisição
      AlertaDeMensagem(
        "alerta",
        "Erro ao obter os dados do CEP:",
        "Erro",
        4000
      );
      console.error("Erro ao obter os dados do CEP:", error);
    }
  }

  async function gravar() {
    let elementos = document.querySelectorAll(`.valores [name]`);
    let fildsvalores = null
    let obrigatorio = []

    if (tipo == 'fornecedor') {
      fildsvalores =  { id_fornecedor: dados.id };
    } else {
      fildsvalores =  { id_cliente: dados.id };
    }
    obrigatorio = [
      "cep",
      "logradouro",
      "numero",
      "bairro",
      "cidade",
      "estado",
      "pais"
    ];



    for (const fild of elementos) {
      let valor = document.querySelector(`#${fild.id}`).value;
      fildsvalores = {
        ...fildsvalores,
        [fild.name]: valor,
      };
    }
    for (const key of obrigatorio) {
      if (!fildsvalores[key]) {
        AlertaDeMensagem("alerta", `é obrigatório passa o ${key}`);
        return;
      }
    }


    try {

      let res; 
      if (tipo == 'fornecedor') {
        res = await api.post(`/fornecedores/insertupdateenderecos`, fildsvalores);
      } else {
        res = await api.post(`/clientes/insertupdateenderecos`, fildsvalores);
      }
      if (res.data.status) {
        setAddEditEndereco(null);
        buscarListaEnderecos();
        AlertaDeMensagem(
          "sucesso",
          "Ação gravada com sucesso",
          "Sucesso!",
          4000
        );
      } else {
        AlertaDeMensagem(
          "alerta",
          "Ação não pode ser gravada, tente novamente",
          "ATENÇÃO!",
          4000
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }

    Spinner(false);
  }


  return (
    <s.ContainerGeral>

      <s.SubContainerGeral>
        <s.ContaineRow>
          <div id="container__addIcon">
            <Fa.FaArrowAltCircleLeft
              onClick={() => { setAddEditEndereco(null) }}
              className="fa fa-times Icon"
              aria-hidden="true"
            />
          </div>

          <div id="container__addIcon">
            <Fa.FaSave
              onClick={() => {
                gravar();
              }}
              className="fa fa-times Icon"
              aria-hidden="true"
            />
          </div>
        </s.ContaineRow>

        <s.ContainerRowGrupo>
          <s.ContainerScroll>
            {filds.map((l) => {
              return (
                <s.ContainerInputPesq key={l.id} className="valores">
                  {l.linha.map((e, i) => {
                    const valor = dados[e.value] !== undefined ? dados[e.value] : '';
                    return (
                      <s.ContainerInputFi
                        key={`${e.value}_${i + 1}`}
                        className={`${e.clas2}`}
                      >
                        <InputKweb
                          className={`salvarbd_ limparinput_ ${e.clas}`}
                          titulo={e.label}
                          type={e.tipo}
                          id={e.value}
                          name={e.value}
                          style={{ width: "100%" }}
                          defaultValue={e.value == "pais" ? 'Brasil' : valor}
                          onChange={(text) =>
                            e.value == "cep" ? setCep(text.target.value) : {}
                          }
                        >
                          {e.select.map((op, index) => (
                            <option key={op.value} value={op.value}>
                              {op.label}
                            </option>
                          ))}
                        </InputKweb>
                      </s.ContainerInputFi>
                    );
                  })}
                </s.ContainerInputPesq>
              );
            })}
          </s.ContainerScroll>
        </s.ContainerRowGrupo>
      </s.SubContainerGeral>

    </s.ContainerGeral>
  );
}
