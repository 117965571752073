import React, { useEffect, useContext, useState } from 'react';
import * as s from './estiloHome'
import * as Fa from "react-icons/fa";
import avatar from '../../img/logo.png'
import { AuthContext } from '../../contexts/authContext';
import api from '../../services/apiAxios';
import AlertaDeMensagem from '../../components/AlertaMensagem/AlertaDeMensagem';
import { useNavigate } from 'react-router-dom';


export default function Home() {
  const { user, setPesquisaAtiva, Spinner } = useContext(AuthContext)
  const [resumo, setResumo] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {

    setResumo(null)
    setPesquisaAtiva(false)
    buscarResumo()

  }, [])

  async function buscarResumo() {
    Spinner(true)

    try {
      const res = await api.get(`/resumo/home`)
      if (res.data.status) {
        setResumo(res.data.resultado)
        Spinner(false)
      } else {
        Spinner(false)
        setResumo(null)
        AlertaDeMensagem('alerta', 'Não existe nenhum produto cadastrado, faça seu primeiro cadastro', 'ATENÇÃO', 4000)
      }

    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);
      AlertaDeMensagem('alerta', 'Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde', 'Opps...', 4000)
    }

  }

  return (
    <s.ContainerGeral>
      <main>
        <div className='main__container'>
          <div className='main__title' >
            <img
              src={user.avatar || avatar} alt='hello'
              style={{ borderRadius: '100%' }}

            />
            <div className='main__greeting'>
              <h1>Olá {user?.nome}</h1>
              <p>Bem vindo ao seu painel</p>
            </div>
          </div>

          {/* Cards  */}
          <div className='main__cards'>

            <div className='card' >
              <Fa.FaUsers className='fa-2x text-lightblue' />
              <div className='card_inner'>
                <p className='text-primary-p' >Número de clientes</p>
                <span className='font-bold text-file' >{resumo?.qtd_clientes}</span>
              </div>
            </div>
            {/* 
            <div className='card' >
              <Fa.FaFileAlt className='fa-2x text-lightblue' />
              <div className='card_inner'>
                <p className='text-primary-p' >Número de pedidos</p>
                <span className='font-bold text-file' >578</span>
              </div>
            </div> */}

            <div className='card' onClick={() => navigate(`/financeiro`)}>
              <Fa.FaMoneyBillAlt className='fa-2x text-red' />
              <div className='card_inner'>
                <p className='text-primary-p' >Inadimplências</p>
                <span className='font-bold text-file' >{resumo?.total_pagamentos_pendentes}</span>
              </div>
              <div className='card_inner'>
                <p className='text-primary-p' >Total</p>
                <span className='font-bold text-file' >{resumo?.total_valor_pendente}</span>
              </div>
            </div>

            <div className='card' >
              <Fa.FaArchive className='fa-2x text-yellow' />
              <div className='card_inner'>
                <p className='text-primary-p' >Número de produtos</p>
                <span className='font-bold text-file' >{resumo?.qtd_produtos}</span>
              </div>
            </div>

            <div className='card' >
              <Fa.FaBars className='fa-2x text-green' />
              <div className='card_inner'>
                <p className='text-primary-p' >Categorias</p>
                <span className='font-bold text-file' >{resumo?.qtd_categorias}</span>
              </div>
            </div>

          </div>

          <div className='charts'>

            <div className='charts__left' >
              <div className='charts__left__title' >
                <div >
                  <h1>Daily Reports</h1>
                  <p>Recife, Pernambuco, BR</p>
                </div>
                <Fa.FaUsb className='fa-2x text-lightblue' />
              </div>
            </div>

            <div className='charts__right' >
              <div className='charts__right__title' >
                <div >
                  <h1>Daily Reports</h1>
                  <p>Recife, Pernambuco, BR</p>
                </div>
                <Fa.FaChartArea className='fa-2x text-lightblue' />
              </div>

              <div className='charts__right__cards'>

                <div className='card1'>
                  <h1>Lucro</h1>
                  <p>R$0,00</p>
                </div>

                <div className='card2'>
                  <h1>Pagamentos</h1>
                  <p>R$0,00</p>
                </div>

                <div className='card3'>
                  <h1>Custos e hospedagens</h1>
                  <p>R$0,00</p>
                </div>

                <div className='card4'>
                  <h1>Banco de dados</h1>
                  <p>R$0,00</p>
                </div>


              </div>

            </div>


          </div>

        </div>
      </main>
    </s.ContainerGeral>

  )
}

