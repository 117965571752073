import React, { useEffect, useContext, useState } from "react";
import * as s from "./estiloFornecedores";
import api from "./../../services/apiAxios";
import { AuthContext } from "../../contexts/authContext";
import Pesquisa from "../../components/pesquisa/pesquisa";
import * as Fa from "react-icons/fa";
import InputKweb from "../../components/InputPersonalizado/input_personalizavel";
import axios from "axios";
import AlertaDeMensagem from "../../components/AlertaMensagem/AlertaDeMensagem";
import sem_clientes from "./img/sem-clientes.png";
import ListaEnderecos from "../../components/Enderecos/lista/listaEndereco";
import CardListaPadrao from "../../components/card_lista_padrao/card_list";
import ExcelReaderFornecedores from "../../components/ExcelReaderFornecedores/ExcelReaderFornecedores";

export default function Fornecedores() {
  const { setPesquisaAtiva, Spinner } = useContext(AuthContext);
  const [fornecSelecionado, setFornecSelecionado] = useState(null);
  const [listaFornecedor, setListaFornecedor] = useState([]);
  const [listaFornecedor2, setListaFornecedor2] = useState([]);
  const [adicionarFornecedor, setAdicionarFornecedor] = useState(false);
  const [emMassa, setEmMassa] = useState(false);
  const [arrayExelFornecedores, setArrayExelFornecedores] = useState([]);
  const cor = ['#17A398', '#963484', '#064789',]
  const [tipo, setTipo] = useState(1)

  const [cep, setCep] = useState("");





  useEffect(() => {
    if (cep.replaceAll("-", "").length == 8) {
      buscarCep(cep);
    }
  }, [cep]);


  //entra aqui quando é edição de Fornecedor
  useEffect(() => {
    if (fornecSelecionado) {
      for (const key in fornecSelecionado) {
        const valor = fornecSelecionado[key];
        try {
          if (key == 'inscricao_estadual_isento') {
            document.getElementById(key).checked = valor;
          } else {
            document.getElementById(key).value = valor;
          }
        } catch (error) {
          null;
        }
      }
    }

  }, [fornecSelecionado, tipo]);

  useEffect(() => {
    if (!adicionarFornecedor) {
      setFornecSelecionado(null);
    }
  }, [adicionarFornecedor]);

  async function buscarCep(cep) {
    let url = `https://viacep.com.br/ws/${cep}/json`;

    // Função assíncrona para fazer a requisição

    try {
      // Faz a requisição GET utilizando Axios e aguarda a resposta
      const response = await axios.get(url);
      // Manipula os dados recebidos
      const dados = response.data;
      if (dados) {
        if (dados.erro) {
          document.querySelector(`#logradouro`).value = "";
          document.querySelector(`#cidade`).value = "";
          document.querySelector(`#estado`).value = "";
          document.querySelector(`#bairro`).value = "";
          return AlertaDeMensagem(
            "alerta",
            "Verifique o cep digitado e tente novamente.",
            "CEP INVÁLIDO",
            4000
          );
        }

        document.querySelector(`#logradouro`).value = dados.logradouro;
        document.querySelector(`#cidade`).value = dados.localidade;
        document.querySelector(`#estado`).value = dados.uf;
        document.querySelector(`#bairro`).value = dados.bairro;
      } else {
        AlertaDeMensagem(
          "alerta",
          "Falha na consulta do endereço.",
          "CEP INVÁLIDO",
          4000
        );
      }
      // Aqui você pode fazer o que quiser com os dados, como exibir na página, etc.
    } catch (error) {
      // Captura e trata qualquer erro ocorrido durante a requisição
      AlertaDeMensagem(
        "alerta",
        "Erro ao obter os dados do CEP:",
        "Erro",
        4000
      );
      console.error("Erro ao obter os dados do CEP:", error);
    }
  }

  function editarFornecedor(cliente) {
    setFornecSelecionado(cliente);
    setAdicionarFornecedor(true);
  }

  useEffect(() => {
    setPesquisaAtiva(false);
    buscarFornecedores();
  }, []);

  function filtraLista(texto) {
    let novoArray = listaFornecedor2.filter(
      (e) =>
        e.nome.toLowerCase().includes(texto.toLowerCase()) ||
        e.sobrenome.toLowerCase().includes(texto.toLowerCase()) ||
        e.id_cliente.toString().includes(texto)
    );
    setListaFornecedor(novoArray);
  }
  async function buscarFornecedores() {
    Spinner(true)
    try {
      const res = await api.get(`/fornecedores`);
      if (res.data.status) {
        setListaFornecedor(res.data.resultado);
        setListaFornecedor2(res.data.resultado);
        Spinner(false)
      } else {
        Spinner(false)
        setListaFornecedor([]);
        setListaFornecedor2([]);
        AlertaDeMensagem(
          "alerta",
          "Não existe nenhum Fornecedor cadastrado, faça seu primeiro cadastro",
          "ATENÇÃO",
          4000
        );
      }
    } catch (error) {
      Spinner(false)
      console.log("error :>> ", error);
      AlertaDeMensagem(
        "alerta",
        "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
        "Opps...",
        4000
      );
    }
  }

  function trataTipoPes(valor, key) {
    if (valor == 'Pessoa Jurídica') {
      return 'Jurídica'
    }
    else if (valor == 'Pessoa Física') {
      return 'Física'
    }
    else if (valor == 'Pessoa Estrangeira') {
      return 'Estrangeira'
    }
    else if (valor == '' && key == 'data_nascimento') {
      return null
    }
    else {
      return valor
    }
  }

  async function gravar() {
    Spinner(true);

    if (emMassa) {

      let grupos = document.querySelectorAll(`.grupo`)

      let arraysFornecedores = []


      for (const e of grupos) {

        let obj = null
        let fornecedores = document.querySelectorAll(`.valores_${e.value} [name]`)

        for (const elem of fornecedores) {
          if (elem.name != 'id_fornecedor') {

            if (elem.name == 'inscricao_estadual_isento') {
              obj = {
                ...obj,
                [elem.name]: elem.checked ? 1 : 0
              }
            }
            else {
              obj = {
                ...obj,
                [elem.name]: trataTipoPes(elem.value, elem.name)
              }
            }

          }
        }
        arraysFornecedores.push(obj)
      }

      for (const key in arraysFornecedores) {
        const element = arraysFornecedores[key];
        console.log('element :>> ', element);
        try {

          const res = await api.post(`/fornecedores/insertupdate`, element);
          if (res.data.status) {
            AlertaDeMensagem(
              "sucesso",
              "Ação gravada com sucesso",
              "Sucesso!",
              4000
            );
          } else {
            AlertaDeMensagem(
              "alerta",
              "Ação não pode ser gravada, tente novamente",
              "ATENÇÃO!",
              4000
            );
          }
        } catch (error) {
          console.log("error :>> ", error);
          AlertaDeMensagem(
            "alerta",
            "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
            "Opps...",
            4000
          );
        }
      }

    } else {


      let elementos = document.querySelectorAll(`.valores [name]`);
      let fildsvalores = null
      let obrigatorio = []

      obrigatorio = [
        "nome",
        "data_nascimento",
      ];

      for (const fild of elementos) {
        let valor = null
        if (fild.id == 'inscricao_estadual_isento') {
          valor = document.querySelector(`#${fild.id}`).checked ? 1 : 0
        }
        else {
          valor = document.querySelector(`#${fild.id}`).value
        }

        fildsvalores = {
          ...fildsvalores,
          [fild.name]: valor,
        };
      }

      for (const key of obrigatorio) {
        if (!fildsvalores[key]) {
          AlertaDeMensagem("alerta", `é obrigatório passa o ${key}`);
          return;
        }
      }

      try {

        const res = await api.post(`/fornecedores/insertupdate`, fildsvalores);
        if (res.data.status) {

          AlertaDeMensagem(
            "sucesso",
            "Ação gravada com sucesso",
            "Sucesso!",
            4000
          );
        } else {
          AlertaDeMensagem(
            "alerta",
            "Ação não pode ser gravada, tente novamente",
            "ATENÇÃO!",
            4000
          );
        }
      } catch (error) {
        console.log("error :>> ", error);
        AlertaDeMensagem(
          "alerta",
          "Ocorreu uma falha na comunicação com o servidor, tente novamente mais tarde",
          "Opps...",
          4000
        );
      }
    }
    setAdicionarFornecedor(false);
    buscarFornecedores();
    Spinner(false);
    setArrayExelFornecedores([]);
  }




  const filds = [
    {
      linha: [
        {
          label: "Id",
          value: "id_fornecedor",
          tipo: "text",
          select: [],
          clas: "",
          clas2: fornecSelecionado != null ? "" : "ocultar",
        },
        {
          label: "*Nome",
          value: "nome",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Nome Fantasia",
          value: "fantasia",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Tipo de Pessoa",
          value: "tipo_pessoa",
          tipo: "select",
          select: [
            { label: "Pessoa Jurídica", value: "Jurídica" },
            { label: "Pessoa Física", value: "Física" },
            { label: "Estrangeira", value: "Estrangeira" },
          ],
          clas: "",
          clas2: "",
        },
      ],
      id: "1",
    },
    {
      linha: [
        {
          label: "CPF",
          value: "cpf",
          tipo: "text",
          select: [],
          clas: "cpf_",
          clas2: "",
        },
        {
          label: "RG",
          value: "rg",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "CNPJ",
          value: "cnpj",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "CRT",
          value: "crt",
          tipo: "select",
          select: [
            { label: "Não definido", value: 1 },
            { label: "Simples nacional", value: 2 },
            { label: "Simples nacional Excesso de sublimite de receita bruta", value: 3 },
            { label: "Regime nacional", value: 4 },
            { label: "MEI", value: 5 },
          ],
          clas: "",
          clas2: "",
        },
      ],
      id: "2",
    },
    {
      linha: [
        {
          label: "Contribuintes",
          value: "contribuintes",
          tipo: "select",
          select: [
            { label: "Contribuinte ICMS", value: 1 },
            { label: "Contribuinte isento de inscrição no cadastro de contribuintes", value: 2 },
            { label: "Não contribuinte", value: 3 },
          ],
          clas: "",
          clas2: "",
        },
        {
          label: "Inscrição Estadual",
          value: "inscricao_estadual",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Inscrição Estadual Isento",
          value: "inscricao_estadual_isento",
          tipo: "checkbox",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Inscrição Municipal",
          value: "inscricao_municipal",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
      ],
      id: "3",
    },
    {
      linha: [
        {
          label: "*Data de Nascimento",
          value: "data_nascimento",
          tipo: "date",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Fone",
          value: "fone",
          tipo: "text",
          select: [],
          clas: "fone_",
          clas2: "",
        },
        {
          label: "E-mail",
          value: "email",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
        {
          label: "Profissão",
          value: "profissao",
          tipo: "text",
          select: [],
          clas: "",
          clas2: "",
        },
      ],
      id: "4",
    },
    {
      linha: [
        {
          label: "Ativo",
          value: "ativo",
          tipo: "select",
          select: [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
          ],
          clas: "",
          clas2: "",
        },
      ],
      id: "5",
    },
  ];
  let array_botoes = [
    {
      icone: <Fa.FaEdit />,
      title: 'Editar',
      funcao: (e) => editarFornecedor(e)
    },
  ]



  return (
    <s.ContainerGeral>
      {!adicionarFornecedor ? (
        <s.SubContainerGeral>

          <s.ContaineRow>
            <Pesquisa
              placeholder="Pesquisa de Fornecedor"
              setValue={filtraLista}
            />

            <div id="container__addIcon">
              <Fa.FaUserPlus
                onClick={() => {
                  setAdicionarFornecedor(true);
                }}
                className="fa fa-times Icon"
                id="Icon"
                aria-hidden="true"
              />
            </div>
          </s.ContaineRow>

          {
            listaFornecedor.length > 0 ? (
              <s.ContainerScroll>
                {
                  listaFornecedor.map((item, index) => (
                    <div key={index}>
                      <CardListaPadrao
                        index={index}
                        item={item}
                        titulo={`${item?.nome} - ${item?.fantasia}`}
                        detalhe1={`Código do Fornecedor ${item?.id_fornecedor}`}
                        // detalhe2={`Profissão: ${item?.profissao}`}
                        ativarClick={false}
                        defaultButon={true}
                        array_botoes={array_botoes}
                      />
                    </div>
                  ))
                }
              </s.ContainerScroll>
            ) : (
              <s.DivVaziaImg>
                <img src={sem_clientes} />
                <h1>Nunhum fornecedor cadastrado</h1>
              </s.DivVaziaImg>
            )
          }

        </s.SubContainerGeral>
      ) : (

        <s.SubContainerGeral>
          <s.ContaineRow2 cor={cor[tipo - 1]}>

            <s.BotaoProdutos
              cor={'#A5ABBA'}
              className='container__addIcon'
              onClick={() => { setTipo(1); setAdicionarFornecedor(false) }}>
              <Fa.FaArrowAltCircleLeft

                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Voltar</p>
            </s.BotaoProdutos>

            <s.BotaoProdutos
              cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
              className='container__addIcon'
              onClick={() => { setTipo(1) }}>
              <Fa.FaUserAlt

                className='fa fa-times Icon'
                id='Icon'
                aria-hidden='true'
              />
              <p>Fornecedor</p>
            </s.BotaoProdutos>
            {
              !emMassa &&
              <s.BotaoProdutos
                cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
                className='container__addIcon'
                onClick={() => {
                  fornecSelecionado ?
                    setTipo(2) :
                    AlertaDeMensagem('alerta', 'É necessário gravar antes os dados do Fornecedor!', 'Obrigatório', 4000)
                }}>
                <Fa.FaSearchLocation
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>Endereços</p>
              </s.BotaoProdutos>
            }

            {
              tipo == 1 &&
              <s.BotaoProdutos
                cor={'#65D178'}
                className='container__addIcon'
                onClick={() => { gravar(); }}>
                <Fa.FaHandshake
                  className='fa fa-times Icon'
                  id='Icon'
                  aria-hidden='true'
                />
                <p>GRAVAR</p>
              </s.BotaoProdutos>
            }


          </s.ContaineRow2>

          {
            tipo == 1 ?
              <s.ContainerRowGrupo>

                <s.ContainerBotoes2 >

                  <s.SelectButtom ativo={!emMassa} lado={1} onClick={() => setEmMassa(false)}>
                    <h4>Adicionar Fornecedor</h4>
                  </s.SelectButtom>
                  <s.SelectButtom ativo={emMassa} lado={2} onClick={() => setEmMassa(true)}>
                    <h4>Adicionar arquivo</h4>
                  </s.SelectButtom>

                </s.ContainerBotoes2>


                <s.ContainerScroll>

                  {
                    emMassa &&
                    <ExcelReaderFornecedores
                      retorno={setArrayExelFornecedores} />
                  }
                  {
                    !emMassa ?
                      <>
                        {filds.map((l, index) => {
                          console.log('l :>> ', l);
                          return (
                            <s.ContainerInputPesq
                              key={index}
                              className="valores">
                              {l.linha.map((e, i) => {
                                return (
                                  <s.ContainerInputFi
                                    key={i}
                                    className={`${e.clas2}`}
                                  >
                                    <InputKweb
                                      className={`salvarbd_ limparinput_ ${e.clas}`}
                                      titulo={e.label}
                                      type={e.tipo}
                                      id={e.value}
                                      name={e.value}
                                      style={{ width: "100%" }}
                                      onChange={(text) =>
                                        e.value == "cep" ? setCep(text.target.value) : {}
                                      }
                                    >
                                      {e.select.map((op, index) => (
                                        <option key={index} value={op.value}>
                                          {op.label}
                                        </option>
                                      ))}
                                    </InputKweb>
                                  </s.ContainerInputFi>
                                );
                              })}
                            </s.ContainerInputPesq>
                          );
                        })}
                      </>

                      :
                      arrayExelFornecedores.length > 0 &&
                      arrayExelFornecedores.map((l, i) => {

                        return (
                          <div style={{ flex: 1 }} key={`key_${i + 1}`} >
                            <ComponenteFornecedor l={l} i={i} arrayExelFornecedores={arrayExelFornecedores} />
                          </div>
                        )
                      })
                  }



                </s.ContainerScroll>
              </s.ContainerRowGrupo>



              : tipo == 2 &&
              <ListaEnderecos
                id={fornecSelecionado.id_fornecedor}
                tipo={'fornecedor'}
              />

          }
        </s.SubContainerGeral>
      )
      }
    </s.ContainerGeral>
  );
}


function ComponenteFornecedor({ l, i, arrayExelFornecedores }) {
  return (
    <s.ContainerFildes key={`k_${i + 1}`} className={`valores_${l?.id_fornecedor}`}  >
      <input
        className='grupo'
        type="hidden"
        defaltValue={l?.id_fornecedor}
      />
      <s.TituloGrupo>Fornecedor {l?.id_fornecedor}</s.TituloGrupo>
      <s.ContainerInputPesq key={1 + i}>

        <s.ContainerInputFi key={`id_fornecedor_${i + 1}`} className="ocultar">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Id do Fornecedor"
            placeholder="Id do Fornecedor"
            type="text"
            name="id_fornecedor"
            defaltValue={l?.id_fornecedor || 0}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`nome_${i + 1}`} className="nome">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="*Nome"
            placeholder="Nome"
            type="text"
            name="nome"
            defaltValue={l?.nome || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`fantasia_${i + 1}`} className="nome_fantasia">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Nome Fantasia"
            placeholder="Nome Fantasia"
            type="text"
            name="fantasia"
            defaltValue={l?.fantasia || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`tipo_pessoa_${i + 1}`} className="tipo_pessoa">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Tipo de Pessoa"
            placeholder="Tipo de Pessoa"
            type="text"
            name="tipo_pessoa"
            defaltValue={l?.tipo_pessoa || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`cpf_${i + 1}`} className="cpf">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="CPF"
            placeholder="CPF"
            type="text"
            name="cpf"
            defaltValue={l?.cpf || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`rg_${i + 1}`} className="rg">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="RG"
            placeholder="RG"
            type="text"
            name="rg"
            defaltValue={l?.rg || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`cnpj_${i + 1}`} className="cnpj">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="CNPJ"
            placeholder="CNPJ"
            type="text"
            name="cnpj"
            defaltValue={l?.cnpj || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`crt_${i + 1}`} className="crt">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="CRT"
            placeholder="CRT"
            type="select"
            name="crt"
            defaltValue={l?.crt || ''}
            style={{ width: '100%' }}

          >

            {[
              { label: "Não definido", value: 1 },
              { label: "Simples nacional", value: 2 },
              { label: "Simples nacional Excesso de sublimite de receita bruta", value: 3 },
              { label: "Regime nacional", value: 4 },
              { label: "MEI", value: 5 },
            ].map((op, index) => (
              <option key={index} value={op.value}>
                {op.label}
              </option>
            ))}
          </InputKweb>
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`contribuintes_${i + 1}`} className="contribuintes">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Contribuintes"
            placeholder="Contribuintes"
            type="select"
            name="contribuintes"
            defaltValue={l?.contribuintes || ''}
            style={{ width: '100%' }}
          >
            {[
              { label: "Contribuinte ICMS", value: 1 },
              { label: "Contribuinte isento de inscrição no cadastro de contribuintes", value: 2 },
              { label: "Não contribuinte", value: 3 },
            ].map((op, index) => (
              <option key={index} value={op.value}>
                {op.label}
              </option>
            ))}
          </InputKweb>
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`inscricao_estadual_${i + 1}`} className="inscricao_estadual">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Inscrição Estadual"
            placeholder="Inscrição Estadual"
            type="text"
            name="inscricao_estadual"
            defaltValue={l?.inscricao_estadual || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`inscricao_estadual_isento_${i + 1}`} className="inscricao_estadual_isento">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Inscrição Estadual Isento"
            placeholder="Inscrição Estadual Isento"
            type="checkbox"
            name="inscricao_estadual_isento"
            checked={l?.inscricao_estadual_isento || false}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`inscricao_municipal_${i + 1}`} className="inscricao_municipal">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Inscrição Municipal"
            placeholder="Inscrição Municipal"
            type="text"
            name="inscricao_municipal"
            defaltValue={l?.inscricao_municipal || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`data_nascimento_${i + 1}`} className="data_nascimento">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="*Data de Nascimento"
            placeholder="Data de Nascimento"
            type="date"
            name="data_nascimento"
            defaultdefaltValue={l?.data_nascimento || null}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`fone_${i + 1}`} className="fone">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Fone"
            placeholder="Fone"
            type="text"
            name="fone"
            defaltValue={l?.fone || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`email_${i + 1}`} className="email">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="E-mail"
            placeholder="E-mail"
            type="text"
            name="email"
            defaltValue={l?.email || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`profissao_${i + 1}`} className="profissao">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Profissão"
            placeholder="Profissão"
            type="text"
            name="profissao"
            defaltValue={l?.profissao || ''}
            style={{ width: '100%' }}
          />
        </s.ContainerInputFi>

        <s.ContainerInputFi key={`ativo_${i + 1}`} className="ativo">
          <InputKweb
            className={`fornecedor_${i + 1}`}
            titulo="Ativo"
            placeholder="Ativo"
            type="select"
            name="ativo"
            defaltValue={l?.ativo || ''}
            style={{ width: '100%' }}
          >
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </InputKweb>
        </s.ContainerInputFi>


      </s.ContainerInputPesq>
    </s.ContainerFildes>
  )

}
