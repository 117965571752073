import styled from 'styled-components';
import { cor_primaria } from '../../styles/coresPadroes';

export const ContainerGeral = styled.div`
    width: 100%;
    max-width: 2518px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @media print {
        background-color: none;
        border: none;
        padding: 0px;
        overflow: hidden;
    }
    `;

export const ContainerTitulo = styled.div`
    width: 100%;
    height: auto;
    background-color: ${cor_primaria};
    text-align: center;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    
    @media (max-width: 500px) {
        h1{
            font-size: 16px;
        }
    }
    
    @media print {
        background-color: #f1f1f1;
        color: #343434;
        border: 1px solid #ccc;
        padding: 4px;
        overflow: hidden;
        
    }
`

//div filho
export const ContainerChildren = styled.div`
    flex: 1;
    overflow: auto;
`

//fechar tela
export const BtnFechar = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* background-color: blue; */
    
    color: #ee1727;
    cursor: pointer;
    font-family: Arial;
    font-size: 18px;
    font-weight: 900;
    padding: 1px;
    text-shadow: 1px 1px #ee1727;
`


