import styled from "styled-components";

export const ContainerGeral = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f4f6;
  padding: 25px;
`;
export const SubContainerGeral = styled.div`
  flex: 1;
  height: 100vh;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
`;
export const ContaineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  #container__addIcon {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  .Icon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .voltar {
    color: #a5aaad;
  }
`;
export const ContaineRow2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 750px){
        flex-direction: column;
        height: auto;
        border-bottom: none;
        .container__addIcon  {

            min-width: 100%;
            height: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;
            padding: 0px 15px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;
export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;
export const ContainerBotoes2 = styled.div`
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 25px;

 
`;
export const SelectButtom = styled.div`
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 10px;
    background-color: ${a => a.ativo ? '#17A398' : '#fff'}  ;
    border-top-left-radius: ${l => l.lado == 1 ? '5px' : '0px'};
    border-bottom-left-radius: ${l => l.lado == 1 ? '5px' : '0px'};
    border-top-right-radius: ${l => l.lado == 2 ? '5px' : '0px'};
    border-bottom-right-radius: ${l => l.lado == 2 ? '5px' : '0px'};
    cursor: pointer;

    h4 {
        font-weight: bold;
        color:${a => a.ativo ?  '#fff' : '#17A398'}
    }
`;
export const ContainerRowGrupo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 1000px;
  /* background-color: red; */
  #container__addIcon {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469cac;
    cursor: pointer;
  }
  .Icon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .voltar {
    color: #a5aaad;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ContainerScroll = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  gap: 20px;
  padding-bottom: 100px;

  @media only screen and (max-width: 600px) {
    height: 55vh;
  }
`;
export const ContainerCard = styled.div`
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};
`;

export const ContainerDados = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;

  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }
`;
export const ContainerBotoes = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
`;
export const ContainerTable = styled.div`
  /* padding: 20px 35px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-top: 5px;
  padding: 4px;

  table {
    width: 100%;
    background-color: #fff;
    font-size: 12px;
  }

  tr, th {
    border: 1px solid #ededed;
  }

  tr:nth-child(even) {
    background: #ededed;
  }
`;
export const Greeting = styled.div`
  h1 {
    font-size: 18px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
`;
export const BotoesCard = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(p) => p.cor};
  padding: 5px 0px;
  gap: 5px;


  svg {
    color: ${(p) => p.cor};
    width: 20px;
    height: 20px;
  }
`;

export const ContainerInputPesq = styled.div`
  width: 100%;
  text-align: center;
  font-family: Arial !important;
  display: inline-flex;
  flex-wrap: wrap;
`;
export const ContainerInputFi = styled.div`
  min-width: 250px;
  flex: 1;
  margin: 4px;
`;

export const BotaoInsertImg = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #469cac;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  margin-top: 15px;
  img {
    width: 84px;
    height: 84px;
    border-radius: 84px;
    object-fit: cover;
  }
`;
export const DivVaziaImg = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  h1 {
    font-size: 18px;
  }
`;

////////////////////////////////////////////////////tela de cadastro da foto
export const ContainerPagCadFotoG = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(m) => (m.mostrar ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;

  padding: 8px;
  z-index: 1000;
  background-color: #000000cf;

  .titulofoto {
    width: 100%;
    text-align: center;
    font-weight: 900;
    text-shadow: 1px 1px 2px #ddd;
    font-size: 12px;
  }
  .titulofoto2 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    text-shadow: 1px 1px 2px #ddd;
    font-size: 10px;
  }

  @media print {
    display: none;
  }
`;

export const ContainerPagCadFotoFechar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: end;
  background-color: #fff;

  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  top: 30px;
  right: 20px;
  color: #ff0000;
  font-size: 28px;
  cursor: pointer;
  text-shadow: 1px 1px 2px #ffbfbf;
  font-weight: 900;

  border: 2px solid #eee;
  border-radius: 20px;
`;

export const ContainerPagCadFotoSalvar = styled.div`
  width: 40px;
  height: 40px;
  display: ${(props) => (props.onoff == 'true' ? "flex" : "none")};
  text-align: center;
  justify-content: center;
  align-items: end;
  background-color: #fff;

  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  top: 60px;
  right: 20px;
  color: #ff0000;
  font-size: 28px;
  cursor: pointer;
  text-shadow: 1px 1px 2px #ffbfbf;
  font-weight: 900;

  border: 2px solid #eee;
  border-radius: 20px;
`;

//cadastro foto
export const ContainerPagCadFotoBox = styled.div`
  width: 350px;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ddd;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 400px) {
    width: 270px;
    height: 270px;
  }

  @media print {
    display: none;
  }
`;

export const ContainerFildes = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    border-radius: 5px;
`;
export const TituloGrupo = styled.h1`
    /* background-color: #fff; */
    font-size: 16px;
    margin: 25px 25px 0px 25px;
    color: #143429;

    @media only screen and (max-width: 600px){
       
    }
`;
export const ContainerPagCadFotoExibir = styled.div`
  flex: 1;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
`;

export const ContainerFotoCadAl = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 60px;
  background-image: url(${(p) => p.img});
  background-color: #f2f3f4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  img {
    max-width: 100px;
    max-height: 100px;
    cursor: pointer;
  }
`;
