import { functionCor } from "../../utils/functions";
import * as Fa from "react-icons/fa";
import * as s from './etyle_card'
import noimage from '../../img/noimage.jpg'
import { useState } from "react";

export default function CardListaPadrao({
    index = null,
    item = null,
    id = null,
    foto = null,
    titulo = null,
    detalhe1 = null,
    detalhe2 = null,
    detalhe3 = null,
    detalhe4 = null,
    detalhe5 = null,
    ativarClick = false,
    defaultButon = false,
    array_botoes = [
        { icone: <Fa.FaEdit />, title: 'Editar', funcao: () => alert('adicione sua ações') },
        { icone: <Fa.FaTrash />, title: 'Excluir', funcao: () => alert('adicione sua ações') },
    ]
}) {

    const [openButons, setOpenButons] = useState(defaultButon)

    return (
        <s.ContainerCard onClick={() => ativarClick ? setOpenButons(!openButons) : {}}
            key={index}>
            <s.ContainerRow cor={functionCor(index)}>
                {
                    foto != null &&
                    <img
                        src={foto != null ? foto : noimage}
                        alt="foto-do-cliente"
                    />
                }
                {
                    id != null &&
                    <s.GreetingCircle cor={functionCor(index)}>
                        <h1>{id}</h1>
                    </s.GreetingCircle>
                }
                <s.Greeting >
                    {
                        titulo &&
                        <h1>{titulo}</h1>
                    }
                    {
                        detalhe1 &&
                        <p>{detalhe1}</p>
                    }
                    {
                        detalhe2 &&
                        <p>{detalhe2}</p>
                    }
                    {
                        detalhe3 &&
                        <p>{detalhe3}</p>
                    }
                    {
                        detalhe4 &&
                        <p>{detalhe4}</p>
                    }
                    {
                        detalhe5 &&
                        <p>{detalhe5}</p>
                    }
                </s.Greeting>
            </s.ContainerRow>
            {
                (openButons) &&
                <s.ContainerOpcoesCard cor={functionCor(index)}>
                    <s.ContainerRow2>

                        {
                            array_botoes.map((e, i) =>
                                <s.Botao key={i + 1} onClick={() => e.funcao(item)} >
                                    {e.icone}
                                    <h4>{e.title}</h4>
                                </s.Botao>
                            )
                        }

                    </s.ContainerRow2>
                </s.ContainerOpcoesCard>
            }

        </s.ContainerCard>
    );
}