import { useEffect, useState } from 'react';
import * as s from './estilo_menu_acoes'
import * as Fa from "react-icons/fa";

export default function MenuAcoes({
    cor = null,
    pesquisa = false, onClickPequisa = null, setValue = null, placeholder = null, funcao = null,
    checkbox = false, onClickCheckbox = null,
    novo = false, onClickNovo = null,
    clonar = false, onClickClonar = null,
    excluir = false, onClickExcluir = null,
    editar = false, onClickEditar = null,
    imprimir = false, onClickImprimir = null,
    arquivar = false, onClickArquivar = null,
    selPesq = false,
    ...resProps

}) {
    const [valor, setValor] = useState('')
    const [checked, setCheked] = useState(false)

    //FunçãoPesquisa
    useEffect(() => {
        if (setValue != null) {
            setValue(valor)
        }
    }, [valor])
    //Função Checkbox
    useEffect(() => {
        if (onClickCheckbox != null) {
            onClickCheckbox(checked)
        }
    }, [checked])


    return (
        <s.ContainerGeral >

            <s.ContainerLeft>
                {
                    checkbox &&
                    <s.ContainerBotoes bg={cor || '#17A398'} onClick={() => setCheked(!checked)}>
                        <div className='outros-icones-check'>
                            {
                                checked ?
                                    <Fa.FaRegCheckSquare

                                        className='fa fa-times'
                                        id='icon-novo'
                                        aria-hidden='true'
                                    />
                                    :
                                    <Fa.FaRegSquare
                                        className='fa fa-times'
                                        id='icon-novo'
                                        aria-hidden='true'
                                    />
                            }

                            <b>Todos</b>
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    novo &&
                    <s.ContainerBotoes bg={cor || '#17A398'} onClick={() => onClickNovo ? onClickNovo() : null}>
                        <div className='novo'>
                            <Fa.FaPlusSquare

                                className='fa fa-times'
                                id='icon-novo'
                                aria-hidden='true'
                            />
                            <b>Novo</b>
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    clonar &&
                    <s.ContainerBotoes bg={1} onClick={() => onClickNovo ? onClickNovo() : null}>
                        <div className='outros-icones'>
                            <Fa.FaClone

                                className='fa fa-times'
                                id='icon-conar'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    editar &&
                    <s.ContainerBotoes bg={1} onClick={() => onClickEditar ? onClickEditar() : null}>
                        <div className='outros-icones'>
                            <Fa.FaEdit

                                className='fa fa-times'
                                id='icon-conar'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    arquivar &&
                    <s.ContainerBotoes bg={1} onClick={() => onClickArquivar ? onClickArquivar() : null}>
                        <div className='outros-icones'>
                            <Fa.FaBox

                                className='fa fa-times'
                                id='icon-conar'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    excluir &&
                    <s.ContainerBotoes bg={1} onClick={() => onClickExcluir ? onClickExcluir() : null}>
                        <div className='outros-icones'>
                            <Fa.FaTrashAlt

                                className='fa fa-times'
                                id='icon-conar'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerBotoes>
                }

                {
                    imprimir &&
                    <s.ContainerBotoes bg={1} onClick={() => onClickImprimir ? onClickImprimir() : null}>
                        <div className='outros-icones'>
                            <Fa.FaPrint

                                className='fa fa-times'
                                id='icon-imprimir'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerBotoes>
                }

            </s.ContainerLeft>
            <s.ContainerRight>

           
                {
                    pesquisa &&
                    <s.ContainerPesquisa>
                        <input
                            placeholder={placeholder || ''}
                            value={valor}
                            onChange={(t) => setValor(t.target.value)}
                        />
                        <div
                            id='container_icone'>
                            <Fa.FaSearch
                                onClick={() => { }}
                                className='fa fa-times'
                                id='icon-pequisa'
                                aria-hidden='true'
                            />
                        </div>
                    </s.ContainerPesquisa>
                }
            </s.ContainerRight>

        </s.ContainerGeral>
    );
}