export const cor_primaria = '#025A6C';
export const cor_segundaria = '#17A398';
export const cor_terciaria = '#347393';



export const cor_letra_primaria = '#fff';

export const cor_alerta = '#FF6666';
export const cor_alerta_claro = '#FFC727';
export const cor_verde_claro = '#6DE4A4';

export const cor_verde_escuro = '#143429';

export const cor_cinza_1 = '#ECECEC';
export const cor_cinza_escuro = '#363539';

export const cor_cinza_bordas = '#ccc';
export const cor_cinza_claro_bordas = '#ccc';


export const cor_extra_1 = '#963484';
export const cor_extra_2 = '#064789';

export const array_cores_telas = [cor_segundaria, cor_extra_1, cor_extra_2];
export const array_cores_cards = ['#2e4a66', '#a98921', '#469cac', '#3b9668'];





// Definições padroes
export const transicao_padrao = 'all 0.3s ease 0s'


