import styled from "styled-components";

export const ContainerGeralComp = styled.div`
    width: 100%;
    height: auto;
    margin-top: 10px;
`;
export const ContainerFildes = styled.div`

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: flex-start; */
    background-color: #fff;
    border-radius: 5px;
    gap: 10px;

`;
export const ContainerRows = styled.div`

    width: 100%;
    height: auto;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    gap: 10px;
`;
export const ContainerInputFi = styled.div`
    min-width: 250px;
    flex: 1;
    margin: 4px;
    display: flex;
    justify-content: center;
`;
export const ContainerFotoCadAl = styled.div`
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 10px;
    border: 1px solid  #eee;
    border-radius: 5px;
    background-image: url(${p => p.img});
    background-color: #F2F3F4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    border-radius: 50px;
    img{
        max-width: 100px;
        max-height: 100px;
        cursor: pointer;
    }

`

export const TituloGrupo = styled.h1`
    font-size: 16px;
    margin: 25px 25px 0px 25px;
    color: #143429;
    text-align: center;
    font-weight: 700;
    @media only screen and (max-width: 600px){
       
    }
`;